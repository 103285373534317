<script setup>
	import { ref, onBeforeMount, computed, watch } from 'vue';
	import { useScreens } from 'vue-screen-utils';

	const { mapCurrent } = useScreens({ xs: '0px', sm: '640px', md: '768px', lg: '1024px', xl: '1240px' });
	const columns = mapCurrent({ xl: 2, lg: 2, md: 2, sm: 1 });

	// get props
	const props = defineProps({
		url: Object
	})

	const rand = Math.random().toString(36).substring(7);

	//  get tomorrow date
	const tomorrow = () => {
		let today = new Date();
		return today.setDate(today.getDate() + 1);
	}

	// set date picker range default value
	const range = ref({
		start: new Date(),
		end: new Date(),
	});

	// update date format for calender date picker
	const masks = ref({
		input: 'DD/MM/YYYY'
	});

	// define ref data source
	let searchTerm = ref('');
	let parkList = ref([]);
	let regionList = ref([]);
	let townList = ref([]);
	let guest = ref({
		adults: 2,
		children: 0,
		infants: 0
	});
	let location = ref("");
	let date = ref({
		start: new Date(),
		end: new Date(tomorrow()),
	});
	let promoCode = ref("");

	// before mount view control
	onBeforeMount(() => {
		// fetch park options via API
		const url = '/api/parks/options';

		fetch(url, {
			method: 'GET'
		})
			.then(res => res.json())
			.then((res) => {
				// update ref data source from API
				// split off and set parkList, regionList and townList from the API response
				parkList.value = res.parks;
				regionList.value = res.regions;
				townList.value = res.towns;
			})
			.catch(error => console.log(error.toString()))

	});

	const asciiConverter = (str) => {
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
	};

	const filterList = (item) => {
		var sub = location.value.toLowerCase() + '';
		var itemName = item.name.toLowerCase();

		if (asciiConverter(itemName).includes(asciiConverter(sub))) {
			return item;
		}
	};

	// search park list base on location search value
	const searchParkList = computed(() => {

		if (location.value === '') {
			return []
		}

		return parkList.value.filter(filterList);
	});

	// search region list base on location search value
	const searchRegionList = computed(() => {

		if (location.value === '') {
			return []
		}

		return regionList.value.filter(filterList);
	});

	// search town list base on location search value
	const searchTownList = computed(() => {

		if (location.value === '') {
			return []
		}

		return townList.value.filter(filterList);
	});

	// update selectedPark value when location value changes
	watch(() => location.value, (newValue, oldValue) => {
		selectedPark.value = newValue;
	});

	const selectPark = (park) => {
		location.value = park;
		// remove selected park value
		setTimeout(() => {
			selectedPark.value = '';
		}, 10)
	}

	let selectedPark = ref('');


	// update date format function
	const format_date = (value, formatType) => {
		if (value) {
			return moment(value).format(formatType)
		}
	}

	// accommodation search form submit handler
	const search = (e) => {
		// get form action
		let targetUrl = e.target.action;
		// generate url params
		let redirecUrl = `?location=${location.value}&dateStart=${format_date(date.value.start, "YYYY/MM/DD")}&dateEnd=${format_date(date.value.end, "YYYY/MM/DD")}&adults=${guest.value.adults}&children=${guest.value.children}&infants=${guest.value.infants}&promoCode=${promoCode.value}`

		// redirect to park finder page
		window.location = targetUrl + redirecUrl;
	}


</script>

<template>
	<div class="c-accomodation-search">
		<form class="c-accommodation-search__form c-form" @submit.stop.prevent="search" :action="props.url.target">
			<div class="c-accomodation-search__input-container">
				<div class="c-accomodation-search__input-wrapper">
					<div class="c-form__field">
						<label class="c-form__label" :for="'location_' + rand">Search for a park</label>
						<div class="c-form__autocomplete">
							<input class="c-form__input" type="text" :id="'location_' + rand" name="location" v-model="location" autocomplete="off" placeholder="Region, town or park" />
							<i class="iconf-search c-form__autocomplete__icon"></i>
							<div v-if="selectedPark !==''">
								<div v-if="searchParkList.length || searchRegionList.length || searchTownList.length">
									<ul class="c-form__autocomplete-panel">
										<li class="c-form__autocomplete__heading" v-if="searchParkList.length">Parks</li>
										<li v-for="p in searchParkList"
											:key="p.name"
											@click="selectPark(p.name)"
											class="c-form__autocomplete__item">
											{{ p.name }}
										</li>
										<li class="c-form__autocomplete__heading" v-if="searchRegionList.length">Regions</li>
										<li v-for="p in searchRegionList"
											:key="p.name"
											@click="selectPark(p.name)"
											class="c-form__autocomplete__item">
											{{ p.name }}
										</li>
										<li class="c-form__autocomplete__heading" v-if="searchTownList.length">Towns / Cities</li>
										<li v-for="p in searchTownList"
											:key="p.name"
											@click="selectPark(p.name)"
											class="c-form__autocomplete__item">
											{{ p.name }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div class="c-form__field">
						<DatePicker trim-weeks
									:first-day-of-week="2"
									v-model="date"
									v-model.range="range"
									step="1"
									:min-date="new Date()"
									:columns="columns"
									:masks="masks"
									id="AvailabilityCalender"
									@did-move="changeMonth" >

							<template #default="{ inputValue, inputEvents }">

								<label class="c-form__label">Dates</label>
								<a class="c-form__input c-form__input-date" v-on="inputEvents.start">
									{{format_date(date.start, 'DD MMM')}} - {{format_date(date.end, 'DD MMM')}}
									<i class="iconf-calendar c-form__autocomplete__icon"></i>
								</a>

							</template>

						</DatePicker>
					</div>
					<div class="c-form__field c-form__field__guest">
						<label class="c-form__label" @click="click()">Guest</label>
						<button class="c-form__input c-form__input-btn" @click.stop.prevent="toggleGuest">{{guest.adults + guest.children + guest.infants}} Guests <i :class="(isOpenGuestTab) ? 'iconf-small-arrow-up' : 'iconf-small-arrow-down' "></i></button>
						<div v-show="isOpenGuestTab" class="c-form__field-guest-wrap">
							<div class="c-form__field">
								<label class="c-form__label" :for="'adults_' + rand">Adults</label>
								<div class="c-form__number-wrapper">
									<a class="decrementer" @click.stop.prevent="guest.adults--, ((guest.adults <= 1) ? guest.adults = 1: guest.adults)"><span class="iconf-minus"></span></a>
									<input v-model.number="guest.adults" type="number" :id="'adults_' + rand" name="adults" min="0" />
									<a class="incrementer" @click.stop.prevent="guest.adults++"><span class="iconf-plus"></span></a>
								</div>
							</div>
							<div class="c-form__field ">
								<label class="c-form__label" :for="'children_' + rand">Children <small>2 - 14 year old</small></label>
								<div class="c-form__number-wrapper">
									<a class="decrementer" @click.stop.prevent="guest.children--, ((guest.children <= 0) ? guest.children = 0: guest.children)"><span class="iconf-minus"></span></a>
									<input v-model.number="guest.children" type="number" :id="'children_' + rand" name="children" min="0" />
									<a class="incrementer" @click.stop.prevent="guest.children++"><span class="iconf-plus"></span></a>
								</div>
							</div>
							<div class="c-form__field">
								<label class="c-form__label" :for="'infants_' + rand">Infants <small>0 - 2 year old</small></label>
								<div class="c-form__number-wrapper">
									<a class="decrementer" @click.stop.prevent="guest.infants--, ((guest.infants <= 0) ? guest.infants = 0: guest.infants)"><span class="iconf-minus"></span></a>
									<input v-model.number="guest.infants" type="number" :id="'infants_' + rand" name="infants" min="0" />
									<a class="incrementer" @click.stop.prevent="guest.infants++"><span class="iconf-plus"></span></a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="c-form__controls">
					<button class="c-accomodation-search__submit" type="submit">Find availability</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>

	import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
	import { ref, computed } from 'vue';
	import 'v-calendar/style.css';
	import moment from 'moment';

	export default {
		name: 'accommodation-search',
		data() {
			return {
				isOpenGuestTab: false,
				moreOption: false,
			}
		},
		mounted() {
		},
		methods: {
			toggleGuest: function () {
				this.isOpenGuestTab = !this.isOpenGuestTab
				console.log(this.$refs.test)
			},


			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},

			tomorrow: function () {
				let today = new Date();
				return today.setDate(today.getDate() + 1);
			},

		}
	};
</script>
