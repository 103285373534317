/* app/ui/nav/large */

import $ from 'jquery';
import * as Core from 'Util/core';
import { publish, subscribe } from 'Util/pubsub';

var NavLarge;
var $header;
var $nav;
var selectors;
var classes;

var navHeight;
var navPos;
var topPos;

var $window;
var $body;
var $anchorLinks;
var $navWithMenus;

var $menubutton;
var $hamnav;

var $slideout;

var $menubuttoncart;
var $menubuttonmember;
var $menubuttonlogin;

var $slideoutcart;
var $slideoutlogin;
var $slideoutbook;

var NavLarge = {
	init: function($elm, selectorList, classList) {
		NavLarge = this;
		$nav = $elm;
		selectors = selectorList;
		classes = classList;

		NavLarge._initElements();
		NavLarge._setSizes();
		NavLarge._initSubscriptions();
		NavLarge._initEvents();
	},

	_setSizes: function() {
		navHeight = $nav.outerHeight();
		navPos = $nav.offset().top;
	},

	_initElements: function () {
		$window = $(window);
		$body = $('body');
		$header = $(selectors.header);
		$navWithMenus = $nav.find(selectors.navItemMega);
		$anchorLinks = $('.js-anchor-link');
		$menubutton = $('.js-expand-collapse__toggle');
		$hamnav = $('#hamnav');
		$slideout = $('.c-slideout__wrapper');

		$menubuttoncart = $('.c-header__cart');
		$menubuttonmember = $('.c-header__member');
		$menubuttonlogin = $('.c-header__book-now');

		$slideoutcart = $('#header-cart');
		$slideoutlogin = $('#header-login');
		$slideoutbook = $('#header-booknow');
	},

	// Listen for window resize event (listener bound on all.js)
	_initSubscriptions: function () {
		//subscribe('/window/resize', NavLarge._setSizes);
	},

	_initEvents: function() {
		$anchorLinks.on('click', NavLarge._processAnchorClick);

		this._bindClickEvents();
	},

	_toggleMegaMenu: function(target, click, event) {
		if (click === 'click') {
			event.preventDefault();
		}

		var $thisTarget = $(target);
		var $thisNav = $thisTarget.is(selectors.navItemMega) ? $thisTarget : $thisTarget.closest(selectors.navItemMega);
		var $expanded = $nav.find(selectors.navItemMega).filter('[aria-expanded="true"]');

		if (click && click === 'click' && $expanded.find(selectors.megamenuHeading)[0] !== target) {
			$expanded.attr('aria-expanded', false);
			$expanded.addClass(classes.allowAnimations);
		}

		if ($thisNav.attr('aria-expanded') === 'true') {
			$thisNav.attr('aria-expanded', false);
			$thisNav.addClass(classes.allowAnimations);
			$header.removeClass(classes.headerMegaMenuOpen);
		} else {
			$thisNav.attr('aria-expanded', true);
			$thisNav.addClass(classes.allowAnimations);
			$header.addClass(classes.headerMegaMenuOpen);
		}

		//megamenu closes hamnav
		if ($hamnav.attr('aria-expanded') === 'true') {
			$menubutton.attr('aria-expanded', 'false');
			$hamnav.attr('aria-expanded', 'false');
		}

		//megamenu closes slideouts
		if ($slideoutcart.attr('aria-expanded') || $slideoutlogin.attr('aria-expanded') || $slideoutbook.attr('aria-expanded') === 'true') {
			$menubutton.attr('aria-expanded', 'false');
			$slideout.attr('aria-expanded', 'false');
		}
	},

	unbind: function () {
		$anchorLinks.off('click', NavLarge._processAnchorClick);
		$navWithMenus.off('click', this._toggleMegaMenu);
		$navWithMenus.off('mouseenter').off('mouseleave');
		$navWithMenus.removeProp('hoverIntent_t');
		$navWithMenus.removeProp('hoverIntent_s');

		this._unbindClickEvents();
	},

	_toggleMegaMenuClick: function(event) {
		publish('/nav/navToggle');
		NavLarge._toggleMegaMenu(this, 'click', event);
	},

	_processAnchorClick: function(event) {
		event.preventDefault();

		var hash = this.href.split('#')[1];
		var $anchor = $('#' + hash);

		if ($anchor.length) {
			Core.scrollToElm($anchor, navHeight -1);
		}
	},

	_bindClickEvents: function() {
		$navWithMenus.each(function() {
			$(this).find(selectors.megamenuHeading).on('click', NavLarge._toggleMegaMenuClick);
		});
		$('body').on('click', NavLarge._handleBodyClick);
	},

	_unbindClickEvents: function() {
		$navWithMenus.each(function() {
			$(this).find(selectors.megamenuHeading).off('click', NavLarge._toggleMegaMenuClick);
		});
		$('body').off('click', NavLarge._handleBodyClick);
	},

	_handleBodyClick: function(e) {
		var $target = $(e.target);
		var $expandedItem;

		//Mega Menu click handle
		var isNotInNavigation = $target.closest(selectors.nav).length === 0;
		var isAnyNavItemExpanded = $nav.find(selectors.navItemMega).filter('[aria-expanded="true"]').length;

		if (isNotInNavigation && isAnyNavItemExpanded) {
			$expandedItem = $nav.find(selectors.navItemMega).filter('[aria-expanded="true"]');

			$expandedItem.attr('aria-expanded', false);
			$expandedItem.addClass(classes.allowAnimations);
			$header.removeClass(classes.headerMegaMenuOpen);
		}

		//Ham Nav click handle
		var isNotHamnavWrapper = $target.closest('.c-hamnav__wrapper').length === 0;
		var isNotHamnavButton = $target.closest('.c-header__mobile-button').length === 0;
		var isHamnavExpanded = $hamnav.is('[aria-expanded="true"]');

		if (isNotHamnavWrapper && isNotHamnavButton && isHamnavExpanded) {
			$menubutton.attr('aria-expanded', 'false');
			$hamnav.attr('aria-expanded', 'false');
		}

		//cart slideout click handle
		var isNotSlideoutWrapper = $target.closest('.c-slideout__wrapper').length === 0;
		var isNotSlideoutButton = $target.closest('.c-header__cart').length === 0;
		var isAnySlideoutExpanded = $slideoutcart.is('[aria-expanded="true"]');

		if (isNotSlideoutWrapper && isNotSlideoutButton && isAnySlideoutExpanded) {
			$slideoutcart.attr('aria-expanded', 'false');
			$menubuttoncart.attr('aria-expanded', 'false');
		}

		//login slideout click handle
		var isNotSlideoutWrapper = $target.closest('.c-slideout__wrapper').length === 0;
		var isNotSlideoutButton = $target.closest('.c-header__member').length === 0;
		var isAnySlideoutExpanded = $slideoutlogin.is('[aria-expanded="true"]');

		if (isNotSlideoutWrapper && isNotSlideoutButton && isAnySlideoutExpanded) {
			$slideoutlogin.attr('aria-expanded', 'false');
			$menubuttonmember.attr('aria-expanded', 'false');
		}

		//book now slideout click handle
		var isNotSlideoutWrapper = $target.closest('.c-slideout__wrapper').length === 0;
		var isNotSlideoutButton = $target.closest('.c-header__book-now').length === 0;
		var isAnySlideoutExpanded = $slideoutbook.is('[aria-expanded="true"]');

		if (isNotSlideoutWrapper && isNotSlideoutButton && isAnySlideoutExpanded) {
			$slideoutbook.attr('aria-expanded', 'false');
			$menubuttonlogin.attr('aria-expanded', 'false');
		}
	}
};

export { NavLarge };
