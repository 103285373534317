<script setup>
	import { shareData } from "../ref/refData";
</script>

<template>

	<button @click="toggleHandler(); shareData.setIsOpen(button)">
		Open cart
	</button>

</template>

<script>
	//import { ref } from 'vue';

	//let setText = ref(text)
	export default {
		name: 'test',
		data() {
			return {
                text: null,
                expanded: false,
                button: false
			}
		},
		mounted() {

		},
		methods: {
            clickHandler: function () {
                this.expanded = !this.expanded;
            },

            toggleHandler: function () {
                this.button = !this.button;
                //shareData.isOpen = !shareData.isOpen;
            }
		}
	};
</script>
