/* app/ui/map/map.control */
/* global MapData */

import $ from 'jquery';
import { Loader } from '@googlemaps/js-api-loader';
import { MapStyles } from 'App/map/map.styles';

// We use the 'Whitesite' account in the Terabyte Stats Google account Developer console
// This needs to be updated to a client account attached to their billing account before go-live
var apiKey = 'AIzaSyA5YSUpmocrsSK00wJhXSqR9Qx69uLXQj8';
var $maps;

var dataAttributes = Object.freeze({
	latLng: 'data-latlng',
	zoom: 'data-zoom'
});

var MapControl = {
	init: function($elms) {

		if (!apiKey) {
			// Can't do anything without an API Key
			return;
		}

		$maps = $elms;

		if ($maps.length) {

			const loader = new Loader({
				apiKey: apiKey,
				version: 'weekly'
			});

			loader.load().then(async () => {
				const { Map } = await google.maps.importLibrary('maps');
				const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

				for (var i = 0; i < $maps.length; i++) {
					var mapCanvas = $maps[i];
					var $thisMap = $maps.eq(i);
					var mapId = 'mapControl_' + i;
					var zoom = parseInt($thisMap.attr(dataAttributes.zoom), 10) || 16;
					var coords = $thisMap.attr(dataAttributes.latLng);
					coords = coords.split(',');
					coords = coords.map(function (el) {
						return parseFloat(el.trim());
					});
					var latLng = { lat: coords[0], lng: coords[1] };

					const styles = new google.maps.StyledMapType(MapStyles, { name: mapId });

					var mapOptions = {
						center: latLng,
						zoom: zoom,
						minZoom: 5,
						fullscreenControl: false,
						mapId: mapId,
						mapTypeControl: true,
						scrollwheel: false,
						streetViewControl: false,
						panControl: false,
						zoomControl: true,
						zoomControlOptions: {
							position: google.maps.ControlPosition.RIGHT_BOTTOM
						}
					};

					var map = new Map(mapCanvas, mapOptions);

					map.mapTypes.set(mapId, styles);
					map.setMapTypeId(mapId);

					// Customise the markers
					let customMarker = new PinElement({
						glyphColor: '#FFF200',
						background: '#005077',
						borderColor: '#005077'
					});

					// Create marker
					let marker = new AdvancedMarkerElement({
						map,
						position: latLng,
						content: customMarker.element
					});
				}
			});
		}

	}
};

export { MapControl };
