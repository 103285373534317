/* app/ui/map/map.load */
/* global MapData */

import $ from 'jquery';
import { subscribe } from 'Util/pubsub';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MapStyles } from 'App/map/map.styles';

// We use the 'Whitesite' account in the Terabyte Stats Google account Developer console
// This needs to be updated to a client account attached to their billing account before go-live
var apiKey = 'AIzaSyA5YSUpmocrsSK00wJhXSqR9Qx69uLXQj8';
var MapData;
var map;

var parkFinderPanelOpen = false;
var apiLoaded = false;

let gmaps = null;
let gmapsCore = null;
let gmapsMarker = null;

let Map = null;
let InfoWindow = null;
let LatLngBounds = null;
let AdvancedMarkerElement = null;
let PinElement = null;

let markers = [];
let markerCluster = null;
let bounds = null;

var MapLoad = {

	updateParkFinderPanel: function() {
		parkFinderPanelOpen = true;
		MapLoad.init(MapData, true);
	},

	init: function (data, parkFinder) {

		MapData = data;

		if (!apiKey) {
			// Can't do anything without an API Key
			return;
		}

		if (parkFinder && !parkFinderPanelOpen) {
			subscribe('/parkFinder/opened', MapLoad.updateParkFinderPanel);
			return;
		}

		var $maps = $('.js-parks-map');
		var firstMap = $maps[0];

		if ($maps.length && (parkFinder === false || (parkFinder === true && parkFinderPanelOpen))) {

			// load map if the api has already been loaded
			if (apiLoaded) {
				MapLoad.updateMap(MapData);
			}
			// load the api if it hasn't been loaded
			else {

				const loader = new Loader({
					apiKey: apiKey,
					version: 'weekly'
				});

				loader.load().then(async () => {
					apiLoaded = true;
					gmaps = await google.maps.importLibrary('maps');
					gmapsCore = await google.maps.importLibrary('core');
					gmapsMarker = await google.maps.importLibrary('marker');

					Map = gmaps.Map;
					InfoWindow = gmaps.InfoWindow;
					LatLngBounds = gmapsCore.LatLngBounds;
					AdvancedMarkerElement = gmapsMarker.AdvancedMarkerElement;
					PinElement = gmapsMarker.PinElement;

					const styles = new google.maps.StyledMapType(MapStyles, { name: 'Top 10' });
					bounds = new LatLngBounds();

					map = new Map(firstMap,
						{
							center: { lat: -34.397, lng: 150.644 },
							zoom: 8,
							minZoom: 5,
							maxZoom: 17,
							fullscreenControl: false,
							mapId: 'Top 10',
							mapTypeControl: false,
							scrollwheel: false,
							streetViewControl: false,
							zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP }
						});

					map.mapTypes.set('Top 10', styles);
					map.setMapTypeId('Top 10');

					await MapLoad.updateMap(MapData);
				});
			}
		}
	},
	updateMap: function (MapData) {

		// Custom cluster renderer
		const renderer = {
			render: ({ count, position }) => {
				// create svg literal with fill color
				const svg = window.btoa(
					`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" width="52" height="52">
							<circle fill="#67B8E6" cx="26" cy="26" opacity="1" r="26" />
							<circle fill="#FFF200" cx="26" cy="26" opacity="1" r="13" /></svg>`);
				const title = `Cluster of ${count} markers`;

				return new google.maps.Marker({
					label: { text: String(count), color: '#005077', fontSize: '12px' },
					position,
					icon: {
						url: `data:image/svg+xml;base64,${svg}`,
						scaledSize: new google.maps.Size(52, 52)
					},
					title: title,
					// adjust zIndex to be above other markers
					zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
				});
			}
		};

		// reset map markers and clusters
		for (let i = 0; i < markers.length; i++) {
			markers[i].setMap(null);
		}
		markers = [];
		if (markerCluster !== null) {
			markerCluster.clearMarkers();
		}

		var parkFinderSlideout = document.getElementById('map-slideout');

		// Loop through the marker data
		for (let i = 0; i < MapData.length; i++) {
			let markerInfo = MapData[i];
			let latLng = { lat: markerInfo.lat, lng: markerInfo.long };

			if (markerInfo.segment === "Premium") {
				var markerColor = '#5A2B73';
			} else if (markerInfo.segment === "Superior") {
				var markerColor = '#005077';
			} else {
				var markerColor = '#88B95B';
			}

			// Customise the markers
			let customMarker = new PinElement({
				glyphColor: '#FFF200',
				background: markerColor,
				borderColor: markerColor
			});

			// Create marker
			let marker = new AdvancedMarkerElement({
				map,
				position: latLng,
				content: customMarker.element
			});

			// Extend bounds of map to include new marker position
			bounds.extend(latLng);

			// InfoWindow content
			let content = '<div class="c-infowindow">';

			if (markerInfo.hasOwnProperty('image') && markerInfo.image !== null) {
				content += '<div class="c-infowindow__image-wrapper"<picture class="c-infowindow__image"><source srcset="' + markerInfo.image.webpUrl + '" type="image/webp" class="c-infowindow__image"><source srcset="' + markerInfo.image.url + '" type="image/jpeg" class="c-infowindow__image"><img class="c-infowindow__image" src="' + markerInfo.image.url + '"/></picture></div>';
			}

			content += '<div class="c-infowindow__details">';

			content += '<div class="c-infowindow__details-tag small ' + markerInfo.segment + '">' + markerInfo.segment + ' Park' + '</div>';

			content += '<div class="c-infowindow__details-copy">';
			content += '<h3 class="c-infowindow__heading">' + markerInfo.name + '</h3>';
			content += '<div class="c-infowindow__address">Address: ' + markerInfo.address + '</div>';
			content += '<div class="c-infowindow__phone">Phone: ' + markerInfo.phone?.name + '</div></div>';

			if (markerInfo.hasOwnProperty('url') && markerInfo.url !== null) {
				content += '<a href="' + markerInfo.url + '" class="c-infowindow__link">View Park</a>';
			}

			content += '</div></div>';

			// Create InfoWindow
			let infoWindow = new InfoWindow({
				content: content
			});

			// Add click event listener to marker
			marker.addListener('click', () => {
				infoWindow.setContent(content);
				infoWindow.open({
					anchor: marker,
					map
				});
			});

			markers.push(marker);
		}

		// Rendering help - marker clusters and fit all markers in bounds
		markerCluster = new MarkerClusterer({ map, markers, renderer });
		map.fitBounds(bounds);

		// Modify the bounds zoom level of pins by taking one zoom step back to prevnt hidden pins
		google.maps.event.addListenerOnce(map, "bounds_changed", function () {

			if (this.getZoom() > 12) {
				this.setZoom(this.getZoom() - 5);
			}
		});

		if (parkFinderSlideout) {
			parkFinderSlideout.addEventListener('transitionend', (event) => {
				if (event.target !== parkFinderSlideout) {
					return;
				}
				map.fitBounds(bounds);
			});
		}
	}
};

export { MapLoad };
