<template>
	<span v-if="cart && cart.length > 0" class="c-header__cart-items">{{cart.length}}</span>
</template>

<script>
	export default {
		name: 'mini-cart',
	
		props: [
			"id"
		],
		data() {
			return {
				cart: []
			}
		},
		mounted() {
			if (localStorage.getItem("cart")) {
				this.cart = JSON.parse(localStorage.getItem("cart"))
			}

			window.addEventListener('cart-updated', (event) => {
				this.cart = event.detail.storage;
			});
		}
	};
</script>
