/* util/cartHelper */

import * as Core from 'Util/core';

var CartHelper = {

	// cart: array[Object]
	// create an array of all end dates from cart items
	// sort low - hight
	// return the last value
	getLatestEndDate: function (cart) {
		const endDates = cart.map((i) => i.date.end);
		endDates.sort();
		return endDates[endDates.length - 1];
	},

	// cart: array[Object]
	// stringify cart object for local storage
	// set contents of local storage key "cart"
	// if there are items in the cart, retrieve the last checkout date and set "lastEndDate" cookie with this value
	// if there are no items in the cart, delete the "lastEndDate" cookie
	saveCart: function (cart) {
		const cartString = JSON.stringify(cart);
		localStorage.setItem('cart', cartString);

		if (cart.length >= 1) {
			const endDate = CartHelper.getLatestEndDate(cart);
			Core.setCookie('lastEndDate', endDate);
		} else {
			Core.deleteCookie('lastEndDate');
		}
	},

	// data: object
	// guestType: string
	// callback: function
	// increment the guest type - check for max occupancy which differs for adults and children
	// if the guest type is incremented, call the callback function
	incrementGuest: function (data, guestType, callback) {
		let getOffers = true;
		data.guest[guestType]++;

		switch (guestType) {
			case 'adult':
				if (data.maxAdultOccupancy === '') {
					break;
				}
				if (data.guest[guestType] > data.maxAdultOccupancy) {
					data.guest[guestType] = data.maxAdultOccupancy;
					getOffers = false;
				}
			break;

			case 'children':
				if (data.maxChildOccupancy === '') {
					break;
				}
				if (data.guest[guestType] > data.maxChildOccupancy) {
					data.guest[guestType] = data.maxChildOccupancy;
					getOffers = false;
				}
			break;
		}
		if (getOffers) {
			callback();
		}
	},

	// data: object
	// guestType: string
	// callback: function
	// decrement the guest type - min occupancy for adults is 1, children 0
	// if the guest type is decremented, call the callback function
	decrementGuest: function (data, guestType, callback) {
		let getOffers = true;
		data.guest[guestType]--;

		switch (guestType) {
		case 'adult':
			if (data.guest[guestType] < 1) {
				data.guest[guestType] = 1;
				getOffers = false;
			}
			break;

		default:
			if (data.guest[guestType] < 0) {
				data.guest[guestType] = 0;
				getOffers = false;
			}
			break;
		}
		if (getOffers) {
			callback();
		}
	},

	guestNumberTyped: function (data, guestType, callback) {
		switch (guestType) {
		case 'adult':
			if (data.maxAdultOccupancy === '') {
				break;
			}
			if (data.guest[guestType] > data.maxAdultOccupancy) {
				data.guest[guestType] = data.maxAdultOccupancy;
			}
			else if (data.guest[guestType] < 1) {
				data.guest[guestType] = 1;
			}
			break;

		case 'children':
			if (data.maxChildOccupancy === '') {
				break;
			}
			if (data.guest[guestType] > data.maxChildOccupancy) {
				data.guest[guestType] = data.maxChildOccupancy;
			} else if (data.guest[guestType] < 0) {
				data.guest[guestType] = 0;
			}
				break;

		default:
			if (data.guest[guestType] < 0) {
				data.guest[guestType] = 0;
			}
			break;
		}

		callback();
	}
};

export { CartHelper };
