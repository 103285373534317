<script setup>
	import { ref, onBeforeMount, computed, watch, onMounted } from 'vue';
	import { debounce } from "Vue/utils";
	import { availabilityData } from "../ref/refData";
	import { useScreens } from 'vue-screen-utils';
	import * as Core from 'Util/core';

	const { mapCurrent } = useScreens({ xs: '0px', sm: '640px', md: '768px', lg: '1024px', xl: '1240px' });
	const columns = mapCurrent({ xl: 2, lg: 2, md: 2, sm: 1 });

	// URL variable - used to check for query strings in other methods
	const url = new URL(window.location);

	// set the dataset retrieved from refData
	let data = availabilityData;
	let parksApiLoaded = false;

	// declare/register the constants that will recieve the responses from API
	let parks = ref([]);
	let parksoptions = ref([]);
	let filteredparks = ref([]);
	let parkList = ref([]);
	let regionList = ref([]);
	let townList = ref([]);

	// register props to expose data comming in from outside
	const props = defineProps({
		region: { type: Number, default: null },
		tag: { type: String, default: null }
	})

	// get tomorrow date
	const tomorrow = () => {
		let today = new Date();
		return today.setDate(today.getDate() + 1);
	};

	// set date picker range default value
	let newrange = ref({
		start: new Date(),
		end: new Date(tomorrow()),
	});

	// layout flags
	let parksLoaded = false;
	let resultslisting = true;

	// define ref data source
	let location = ref('');

	// call methods that we want to init before the module renders
	onBeforeMount(() => {
		parksBookings();
		parksAPI();
		parksOptions();
	})

	//====------------ Parks API ------------====//

	const parksAPI = () => {

		const ParksAPI = `/api/parks`;

		fetch(ParksAPI, {
			method: 'GET'
		})
			.then(res => res.json())
			.then((res) => {
				if (res) {
					// set parks and filteredparks to be the response collection from API
					parks.value = res;
					//filteredparks.value = res;
					// create empty parameters and attach to res, used for filtering
					res.filterpark = null;
					res.filtertype = null;
					res.filterbest = null;
					parksApiLoaded = true;
					// call the parksQuery method to check for querystring in the URL
					parksQuery();
				}
			})
			.catch(error => console.log(error.toString()))
	}

	//====------------ Parks Options API ------------====//

	const parksOptions = () => {

		const ParksOptions = `/api/parks/options`;

		fetch(ParksOptions, {
			method: 'GET'
		})
			.then(res => res.json())
			.then((res) => {
				if (res) {
					// set parksoptions to be the response collection from API
					parksoptions.value = res;
					// split off and set parkList, regionList, and townList from the API response
					parkList.value = res.parks;
					regionList.value = res.regions;
					townList.value = res.towns;
					// call the parksRegion method to check for an ID value from the region prop
					parksRegion();
				}
			})
			.catch(error => console.log(error.toString()))
	}

	//====------------ Park Query Stings --------------====//

	// checks for query string in the URL and get dates to set data with new dates
	const parksBookings = () => {

		const dateStart = url.searchParams.get('dateStart');
		const dateEnd = url.searchParams.get('dateEnd');

		if (dateStart && dateEnd) {
			newrange.value.start = new Date(dateStart)
			newrange.value.end = new Date(dateEnd)
			data.value.date.start = new Date(dateStart)
			data.value.date.end = new Date(dateEnd)
		} else {
			newrange.value.start = new Date()
			newrange.value.end = new Date(tomorrow())
		}
	}

	// checks for query string in the URL and get new parameters for filtering
	const parksQuery = () => {

		const el = document.getElementById('ParkFinder');
		var category = parks.value;

		// gets location and sets empty filterpark parameter to be new value. Fires the selectPark method with value
		const qLocation = url.searchParams.get('location');
		if (qLocation !== null && qLocation.length > 0) {
			category.filterpark = qLocation;
			location.value = qLocation;
			selectPark(location.value);
			el.scrollIntoView({ behavior: "smooth" });
		} else if (props.region === null) {
			FilteredParks();
		}

		// gets guests info and emmends data with values
		const adults = url.searchParams.get('adults');
		if (adults !== null && adults.length > 0) {
			data.value.guest.adult = parseInt(adults);
		}

		const children = url.searchParams.get('children');
		if (children !== null && children.length > 0) {
			data.value.guest.children = parseInt(children);
		}

		const infants = url.searchParams.get('infants');
		if (infants !== null && infants.length > 0) {
			data.value.guest.infant = parseInt(infants);
		}

		const qPromoCode = url.searchParams.get('promoCode');
		if (qPromoCode !== null && qPromoCode.length > 0) {
			data.value.promoCode = qPromoCode;
		}

		localStorage.setItem('bookingParams', JSON.stringify(data.value));
	}

	//====------------ Region ID passed to filter --------------====//

	// checks for and retrieves region value from prop. Filters data by ID and gets name. Fires method selectPark with value
	const parksRegion = () => {

		if (parksApiLoaded) {
			let regionData = [];
			regionData = regionData.concat(parkList.value, regionList.value, townList.value);
			const selectedParkID = props.region;
			let regionProp = regionData.find(parkproperty => parkproperty.id === selectedParkID);

			if (regionProp !== undefined) {
				const regionName = regionProp.name;
				selectPark(regionName);
			}
		} else {
			setTimeout(() => {
				parksRegion();
			}, 250);
		}
	};

	//====------------ Park Search Bar ------------====//

	// search park list base on location search value
	const asciiConverter = (str) => {
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
	};

	const filterList = (item) => {
		var sub = location.value.toLowerCase() + '';
		var itemName = item.name.toLowerCase();

		if (asciiConverter(itemName).includes(asciiConverter(sub))) {
			return item;
		}
	};

	// search park list base on location search value
	const searchParkList = computed(() => {

		if (location.value === '') {
			return []
		}

		return parkList.value.filter(filterList);
	});

	// search region list base on location search value
	const searchRegionList = computed(() => {

		if (location.value === '') {
			return []
		}

		return regionList.value.filter(filterList);
	});

	// search town list base on location search value
	const searchTownList = computed(() => {

		if (location.value === '') {
			return []
		}

		return townList.value.filter(filterList);
	});

	// update selectedPark value when location value changes
	watch(() => location.value, (newValue, oldValue) => {
		selectedPark.value = newValue;
	});

	// update bookingParams in local storage on data change
	watch(() => [
		data.value.date.start,
		data.value.date.end,
		data.value.guest.adult,
		data.value.guest.children,
		data.value.guest.infant,
		data.value.promoCode
	], (newValue, oldValue) => {
		localStorage.setItem('bookingParams', JSON.stringify(data.value));
	});

	const selectPark = (park) => {

		location.value = park;

		// remove selected park value
		setTimeout(() => {
			selectedPark.value = '';
		}, 10)

		filterParksBar(park);
	}

	let selectedPark = ref('');

	//====------------ Calendar ------------====//

	// update date format function
	const format_date = (value, formatType) => {
		if (value) {
			return moment(value).format(formatType)
		}
	}

	//====------------ Filters ------------====//

	//search box filter passing value to main filteredParks method
	const filterParksBar = (park) => {
		var category = parks.value;
		var selection = park;
		var searchicon = document.querySelector('#ParkFinderSearchIcon');

		searchicon.classList.remove('iconf-search');
		searchicon.classList.add('iconf-close');

		category.filterpark = selection;
		parks.value = category;

		FilteredParks();
	}

	// reset data upon filter criteria removal
	const unfilterParksBar = () => {
		var category = parks.value;
		var searchicon = document.querySelector('#ParkFinderSearchIcon');

		searchicon.classList.add('iconf-search');
		searchicon.classList.remove('iconf-close');

		category.filterpark = null;
		location.value = "";

		parks.value = category;

		resultslisting = true;

		FilteredParks();
	}

	// main parks filter method that ties all the parameters together, looking at the params passed to res and applies conditional filtering.
	// produces a new set of data on every execution which feeds to the two main listing components, map and panel.
	const FilteredParks = () => {

		var category = parks.value;

		var filterparkvalue = category.filterpark;
		var filtertypevalue = category.filtertype;
		var filterbestvalue = category.filterbest;

		if (filterparkvalue !== null && filtertypevalue === null && filterbestvalue === null) {

			let categoryFilter = category.filter(category => category.tags.find(element => element.name === filterparkvalue));

			categoryFilter.filterpark = filterparkvalue;
			categoryFilter.filtertype = filtertypevalue;
			categoryFilter.filterbest = filterbestvalue;

			filteredparks.value = categoryFilter;

		} else if (filtertypevalue !== null && filterbestvalue === null && filterparkvalue === null) {

			let categoryFilter = category.filter(category => category.tags.find(element => element.name === filtertypevalue));

			categoryFilter.filterpark = filterparkvalue;
			categoryFilter.filtertype = filtertypevalue;
			categoryFilter.filterbest = filterbestvalue;

			filteredparks.value = categoryFilter;

		} else if (filterbestvalue !== null && filtertypevalue === null && filterparkvalue === null) {

			let categoryFilter = category.filter(category => category.tags.find(element => element.name === filterbestvalue));

			categoryFilter.filterpark = filterparkvalue;
			categoryFilter.filtertype = filtertypevalue;
			categoryFilter.filterbest = filterbestvalue;

			filteredparks.value = categoryFilter;

		} else if (filterparkvalue !== null && filtertypevalue !== null && filterbestvalue === null) {

			let categoryFilter = category
				.filter(category => category.tags.find(element => element.name === filterparkvalue))
				.filter(category => category.tags.find(element => element.name === filtertypevalue));

			categoryFilter.filterpark = filterparkvalue;
			categoryFilter.filtertype = filtertypevalue;
			categoryFilter.filterbest = filterbestvalue;

			filteredparks.value = categoryFilter;

		} else if (filterparkvalue !== null && filterbestvalue !== null && filtertypevalue === null) {

			let categoryFilter = category
				.filter(category => category.tags.find(element => element.name === filterparkvalue))
				.filter(category => category.tags.find(element => element.name === filterbestvalue));

			categoryFilter.filterpark = filterparkvalue;
			categoryFilter.filtertype = filtertypevalue;
			categoryFilter.filterbest = filterbestvalue;

			filteredparks.value = categoryFilter;

		} else if (filtertypevalue !== null && filterbestvalue !== null && filterparkvalue === null) {

			let categoryFilter = category
				.filter(category => category.tags.find(element => element.name === filtertypevalue))
				.filter(category => category.tags.find(element => element.name === filterbestvalue));

			categoryFilter.filterpark = filterparkvalue;
			categoryFilter.filtertype = filtertypevalue;
			categoryFilter.filterbest = filterbestvalue;

			filteredparks.value = categoryFilter;

		} else if (filtertypevalue !== null && filterbestvalue !== null && filterparkvalue !== null) {

			let categoryFilter = category
				.filter(category => category.tags.find(element => element.name === filterparkvalue))
				.filter(category => category.tags.find(element => element.name === filtertypevalue))
				.filter(category => category.tags.find(element => element.name === filterbestvalue));

			categoryFilter.filterpark = filterparkvalue;
			categoryFilter.filtertype = filtertypevalue;
			categoryFilter.filterbest = filterbestvalue;

			filteredparks.value = categoryFilter;

		} else {
			filteredparks.value = parks.value
		}

		parksLoaded = true;
		resultslisting = true;
	}

</script>

<template>
	<div class="c-park-finder">

		<div class="c-park-finder__filter">
			<div class="c-park-finder__filter-wrap">
				<div class="c-park-finder__filter-searchbar-container">
					<div class="c-park-finder__title">Find a park</div>
					<div class="c-park-finder__searchbar-wrapper">
						<div class="c-form__autocomplete">
							<input class="c-form__input" type="text" id="location" name="location" v-model="location" autocomplete="off" required placeholder="Region, town or park" />
							<i id="ParkFinderSearchIcon" class="c-form__autocomplete__icon iconf-search" @click="unfilterParksBar()"></i>
							<div v-if="selectedPark !==''">
								<div v-if="searchParkList.length || searchRegionList.length || searchTownList.length">
									<ul class="c-form__autocomplete-panel">
										<li class="c-form__autocomplete__heading" v-if="searchParkList.length">Parks</li>
										<li v-for="p in searchParkList"
											:key="p.name"
											@click="selectPark(p.name)"
											class="c-form__autocomplete__item">
											{{ p.name }}
										</li>
										<li class="c-form__autocomplete__heading" v-if="searchRegionList.length">Regions</li>
										<li v-for="p in searchRegionList"
											:key="p.name"
											@click="selectPark(p.name)"
											class="c-form__autocomplete__item">
											{{ p.name }}
										</li>
										<li class="c-form__autocomplete__heading" v-if="searchTownList.length">Towns / Cities</li>
										<li v-for="p in searchTownList"
											:key="p.name"
											@click="selectPark(p.name)"
											class="c-form__autocomplete__item">
											{{ p.name }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="c-park-finder__filters-wrapper">
					<div class="c-park-finder__filters js-park-finder__filters">
						<div class="c-park-finder__filters-buttons">
							<a class="c-park-finder__filters-button js-park-finder__filters-button c-expand-collapse__trigger js-expand-collapse__toggle" aria-controls="Date" :aria-current="data.hasValue.calendar" :aria-expanded="false">{{format_date(data.date.start, 'DD MMM')}} - {{format_date(data.date.end, 'DD MMM')}}</a>
							<a class="c-park-finder__filters-button js-park-finder__filters-button c-expand-collapse__trigger js-expand-collapse__toggle" aria-controls="Guest" :aria-expanded="false" tabindex="1" @focusout="handleFocusOut">{{data.guest.adult + data.guest.children + data.guest.infant}} Guests</a>
							<div class="c-park-finder__filter-promo">
								<label class="c-park-finder__filter-promo-label">Promo code</label>
								<input class="c-park-finder__filter-promo-input c-form__input" type="text" id="promoCode" name="promoCode" v-model="data.promoCode" autocomplete="off" placeholder="Promo code" />
							</div>
						</div>

						<div class="c-park-finder__filters-dropdown js-park-finder__filters-dropdown js-expand-collapse allow-animations" :aria-expanded="false" id="Date" data-expand-collapse-animation="slideDown" data-expand-collapse-set="ParkFinder">
							<div class="c-expand-collapse__body js-expand-collapse__item-body">
								<div class="c-park-finder__filters-dropdown-wrapper">

									<DatePicker trim-weeks
												:first-day-of-week="2"
												v-model="data.date"
												id="AvailabilityCalender"
												:min-date="new Date()"
												step="1"
												:columns="columns"
												v-model.range="range"
												@did-move="changeMonth" />

									<div class="c-park-finder__filters-dropdown-close nopadding">
										<button class="c-park-finder__filters-dropdown-close-button c-expand-collapse__trigger js-expand-collapse__close" aria-expanded="true">Close</button>
									</div>
								</div>
							</div>
						</div>

						<div class="c-park-finder__filters-dropdown js-park-finder__filters-dropdown js-expand-collapse allow-animations" :aria-expanded="false" id="Guest" data-expand-collapse-animation="slideDown" data-expand-collapse-set="ParkFinder">
							<div class="c-expand-collapse__body js-expand-collapse__item-body">
								<div class="c-park-finder__filters-dropdown-wrapper">
									<form class="c-form">
										<div class="c-form__field c-form__field-group-two-col">
											<label class="c-form__label c-park-finder__filters-dropdown-guests-label" for="adults">Adults</label>
											<div class="c-form__number-wrapper">
												<a class="decrementer" @click.stop.prevent="data.guest.adult--, ((data.guest.adult <= 1) ? data.guest.adult = 1: data.guest.adult)"><span class="iconf-minus"></span></a>
												<input v-model.number="data.guest.adult" type="number" min="0" max="{{data.maxAdultOccupancy}}" />
												<a class="incrementer" @click.stop.prevent="data.guest.adult++"><span class="iconf-plus"></span></a>
											</div>
										</div>
										<div class="c-form__field c-form__field-group-two-col">
											<label class="c-form__label c-park-finder__filters-dropdown-guests-label" for="children">Children <small>2 - 14 year old</small></label>
											<div class="c-form__number-wrapper">
												<a class="decrementer" @click.stop.prevent="data.guest.children--, ((data.guest.children <= 0) ? data.guest.children = 0: data.guest.children)"><span class="iconf-minus"></span></a>
												<input v-model.number="data.guest.children" type="number" min="0" max="{{data.maxChildOccupancy}}" />
												<a class="incrementer" @click.stop.prevent="data.guest.children++"><span class="iconf-plus"></span></a>
											</div>
										</div>
										<div class="c-form__field c-form__field-group-two-col">
											<label class="c-form__label c-park-finder__filters-dropdown-guests-label" for="infants">Infants <small>0 - 2 year old</small></label>
											<div class="c-form__number-wrapper">
												<a class="decrementer" @click.stop.prevent="data.guest.infant--, ((data.guest.infant <= 0) ? data.guest.infant = 0: data.guest.infant)"><span class="iconf-minus"></span></a>
												<input v-model.number="data.guest.infant" type="number" min="0" max="{{data.maxChildOccupancy}}" />
												<a class="incrementer" @click.stop.prevent="data.guest.infant++"><span class="iconf-plus"></span></a>
											</div>
										</div>
									</form>
									<div class="c-park-finder__filters-dropdown-close">
										<button class="c-park-finder__filters-dropdown-close-button c-expand-collapse__trigger js-expand-collapse__close" aria-expanded="true">Close</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="c-park-finder__map-button-wrapper">
					<button class="c-park-finder__map-button js-expand-collapse__toggle" aria-controls="map-slideout" v-if="resultslisting" aria-expanded="false" @click="FullPanel()">
						<div class="map-close">
							<span class="iconf-close"></span>
							Close map
						</div>
						<div class="map-open" @click="LoadMapComponent">
							<span class="iconf-address"></span>
							Open map
						</div>
					</button>
				</div>
			</div>
		</div>

		<div class="c-park-finder__results-wrapper">
			<!-- Map -->
			<div class="c-park-finder__results-slideout-wrapper c-park-finder__results-map-wrapper js-expand-collapse allow-animations" id="map-slideout" aria-expanded="false">
				<div class="c-park-finder__results-slideout c-park-finder__results-map">
					<locations v-bind:MapData="filteredparks" :ParkFinder="true"></locations>
				</div>
			</div>

			<!-- Parks Listing -->
			<div class="c-park-finder__results-slideout-wrapper c-park-finder__results-listing-wrapper js-expand-collapse allow-animations">
				<div class="c-park-finder__results-slideout" v-if="parksLoaded">
					<div class="c-park-finder__results-listing" v-if="filteredparks && filteredparks.length > 0">
						<parklisting v-for="item in filteredparks" v-bind:park="item" :availability-data="data" :tag="props.tag"></parklisting>
					</div>
					<div class="c-park-finder__empty" v-else>
						<div class="c-park-finder__empty-wrapper">
							<div class="c-park-finder__empty-image">
								<img :src="'/content/images/icons/map-marker-2-128.png'" />
							</div>
							<div class="c-park-finder__empty-copy">
								There are no results for your selection.
							</div>
						</div>
					</div>
				</div>
				<div class="c-park-finder__results-slideout" v-else>
					<div class="c-park-finder__empty">
						<div class="c-park-finder__empty-wrapper">
							<div class="loader">
								<span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { publish } from 'Util/pubsub';
	import locations from "../locations/locations";
	import parkListing from "../parkListing/parkListing";
	// even though this is grey, it is needed for the calender control
	import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
	import 'v-calendar/style.css';
	import moment from 'moment';

	export default {
		name: 'parkfinder',
		components: {
			parkListing,
			locations
		},
		data() {
			return {
				parks: [],
				activeType: null,
				activeItem: null,
				activeTypesButton: false,
				activeBestButton: false,
				firstloadType: true,
				firstloadItem: true,
				loadMap: false
			}
		},
		mounted() {

		},
		methods: {

			format_date(value, formatType) {
				if (value) {
					this.hasValue.calendar = true;
					return moment(value).format(formatType)
				}
			},

			FullPanel() {
				var panel = document.querySelector('.c-park-finder__results-listing-wrapper');
				panel.classList.toggle('Full');
			},

			LoadMapComponent() {
				// only fire on first open of the map panel
				if (this.loadMap === false) {
					this.loadMap = true;
					publish('/parkFinder/opened');
				}
			},
		}
	};
</script>
