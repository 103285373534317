<template>
	<div class="c-cart-slideout__items">
		<div class="c-cart-slideout__items-wrapper">

			<div v-if="loading">
				<div class="c-cart__loader">
					<span class="c-cart__loader-message">Updating cart</span>
					<div class="loader">
						<span></span>
					</div>
				</div>
				<a class="c-cart-clear-side" @click="clearcart()">
					<span class="iconf-trash"></span>
					Clear Cart
				</a>
			</div>
			<div v-if="cart">
				<cartItemSmall v-bind:cart="cart"></cartItemSmall>
				<div class="c-cart-slideout__item c-cart-slideout__item--membership" v-if="hasMembership">
					<div class="c-cart-slideout__item-copy">
						<div class="c-cart-slideout__item-heading">{{membership.Description}}</div>
						<div class="c-cart-slideout__details-price">{{toNZD(parseFloat(membership.Amount, 10))}}</div>
					</div>
					<div class="c-cart-slideout__item-actions">
						<div class="c-cart-slideout__item-actions-delete iconf-trash" @click="removeMembership()"></div>
					</div>
				</div>
				<div class="c-cart-slideout__totals">
					<div class="c-cart-slideout__totals-price">
						Extras<span>{{toNZD(getTotalExtra())}}</span>
					</div>
					<div class="c-cart-slideout__totals-price" v-if="getTotalPrice('yourDiscountAmount') > 0">
						Discount Applied
						<span>{{toNZD(getTotalPrice('yourDiscountAmount'))}}</span>
					</div>
					<div class="c-cart-slideout__totals-price" v-if="getTotalPrice('potentialDiscountAmount') > 0">
						Members save
						<span>{{toNZD(getTotalPrice('potentialDiscountAmount'))}}</span>
					</div>
					<div class="c-cart-slideout__totals-total">
						Total price
						<span>{{toNZD(totalPrice())}}</span>
					</div>
				</div>
				<a class="c-cart-slideout__checkout-button" href="/cart/">View Cart & Check out</a>
				<a :href="continueshoppinglink" class="c-cart-slideout__continue-shopping">{{continueshoppingname}}</a>
				<a class="c-cart-clear-side" @click="clearcart()">
					<span class="iconf-trash"></span>
					Clear Cart
				</a>
			</div>
			<div class="c-cart-slideout__empty" v-else>
				<img class="c-cart-slideout__empty-icon" :src="'/content/images/icons/cart-empty.svg'" />
				<div class="c-cart-slideout__empty-text">Your cart is currently empty.</div>
				<a class="c-cart-slideout__empty-book-button js-expand-collapse__toggle" aria-controls="header-booknow" aria-expanded="false">Book Now</a>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import cartItemSmall from "../cartItemSmall/cartItemSmall.vue";
	import * as Core from 'Util/core';
	import { CartHelper } from 'Util/cartHelper';

	export default {
		name: 'slideoutcart',
		components: {
			cartItemSmall
		},
		props: [
			"id",
			"continueshoppinglink",
			"continueshoppingname"
		],
		data() {
			return {
				loading: true,
				cart: null,
				cartEmpty: false,
				hasMembership: false,
				membership: {}
			}
		},
		mounted() {

			this.refreshCart(false);

			window.addEventListener('cart-updated', (event) => {
				this.refreshCart(false);
			});

			if (Core.checkCookie('cart-buyMembership')) {
				this.hasMembership = true;
				this.membership = JSON.parse(decodeURIComponent(Core.getCookie('cart-buyMembership')));
			}
		},
		methods: {

			removeMembership: function () {
				Core.deleteCookie('cart-buyMembership');
				this.hasMembership = false;
				this.refreshCart(true);
			},

			refreshCart: function (forceRefresh) {
				let i = 0;
				let cartJson = [];
				let refreshedCart = [];
				let memberEmail = '';
				const storedCart = localStorage.getItem('cart');

				if (storedCart) {
					cartJson = JSON.parse(storedCart);
				}

				if (!storedCart || cartJson.length === 0) {
					this.cart = null;
					this.cartEmpty = true;
					this.loading = false;
					return;
				}

				if (document.getElementById('memberEmail')) {
					memberEmail = document.getElementById('memberEmail').dataset.memberEmail;
				}

				cartJson.forEach((cartItem) => {

					if ((memberEmail !== cartItem.memberEmail) || forceRefresh) {

						const pricingApiURL = `/api/hotels/${cartItem.hotelId}/rooms/${cartItem.roomTypeId}/pricing/${this.format_date(cartItem.date.start, 'YYYY-MM-DD')}/${this.format_date(cartItem.date.end, 'YYYY-MM-DD')}?adults=${cartItem.guest.adult}&children=${cartItem.guest.children}&infants=${cartItem.guest.infant}&promoCode=${cartItem.promoCode}&allotmentId=${cartItem.allotmentId}`;

						fetch(pricingApiURL, {
							method: 'GET'
						})
							.then(res => res.json())
							.then((res) => {

								let data = res.offers[0];

								if (data.dailyBreakdown) {
									cartItem.dailyBreakdown = data.dailyBreakdown;
								}

								cartItem.warning = false;
								cartItem.memberEmail = memberEmail;
								cartItem.totalAmount = data.totalAmount;
								cartItem.potentialDiscountAmount = data.potentialDiscountAmount;
								cartItem.yourDiscountAmount = data.yourDiscountAmount;
								cartItem.isAvailable = data.isAvailable;
								cartItem.bookingDetail = data.bookingDetail;

								refreshedCart.push(cartItem);
							})
							.catch((error) => {
								console.log(error.toString());
								cartItem.warning = true;
								refreshedCart.push(cartItem);
							})
							.finally(() => {
								i++;

								if (i === cartJson.length) {
									refreshedCart.sort((a, b) => {
										const dateA = a.date.start;
										const dateB = b.date.start;

										if (dateA < dateB) {
											return -1;
										}
										if (dateA > dateB) {
											return 1;
										}
										return 0;
									});
									this.cart = refreshedCart;
									this.loading = false;

									CartHelper.saveCart(this.cart);
								}
							});
					} else {
						cartItem.warning = false;
						refreshedCart.push(cartItem);
						i++;

						if (i === cartJson.length) {
							this.cart = refreshedCart;
							this.loading = false;

							CartHelper.saveCart(this.cart);
						}
					}
				});
			},

			getTotalPrice: function (property) {
				let cartItems = this.cart;
				let sumTotalPrice = cartItems.reduce((accumulator, object) => {
					return accumulator + object[property];
				}, 0);

				return sumTotalPrice;
			},

			getTotalExtra: function () {
				let cartItems = this.cart;
				let extraPriceList = [];

				cartItems.forEach((c) => {
					let chargeableExtras = c.chargeableExtras;
					chargeableExtras.forEach((extra) => {
						extraPriceList.push(extra.bookedTotal)
					})
				});

				let sumTotalExtraPrice = extraPriceList.reduce((accumulator, a) => {
					return accumulator + a;
				}, 0);

				this.extra = sumTotalExtraPrice;

				return sumTotalExtraPrice;
			},

			totalPrice() {
				let cartItems = this.cart;
				let totalChargePerBooking = [];

				cartItems.forEach((c) => {
					totalChargePerBooking.push(c.totalAmount);
					let chargeableExtras = c.chargeableExtras;

					chargeableExtras.forEach((extra) => {
						totalChargePerBooking.push(extra.bookedTotal)
					})
				})

				if (this.hasMembership) {
					totalChargePerBooking.push(this.membership.Amount);
				}

				let totalCharges = totalChargePerBooking.reduce((accumulator, a) => {
					return accumulator + a;
				}, 0);

				return totalCharges;
			},

			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},

			clearcart: function () {

				var cartdata = [];

				if (confirm("Are you sure want to clear your cart?") == true) {
					localStorage.setItem("cart", JSON.stringify(cartdata))
				}

				location.reload();

				removeMembership();
			}
		}
	};
</script>
