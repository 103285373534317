/* app/ui/popup/popup-promotion */

import $ from 'jquery';
import * as Core from 'Util/core';
import { publish, subscribe } from 'Util/pubsub';
import Cookies from 'js-cookie';

const selectors = {
	container: '#modal-popup',
	initial: '.js-popup-promo__initial',
	result: '.js-popup-promo__success',
	cta: '.js-popup-promo__cta',
	countdown: '.js-countdown',
	days: '.js-countdown__days',
	hours: '.js-countdown__hours',
	mins: '.js-countdown__mins'
};

let popupDelaySeconds = 5;

let module = {
	init: function () {
		var $container = $(selectors.container);
		if ($container) {
			// timed popup
			window.setTimeout(function () { publish('/modal/open', 'modal-popup'); }, popupDelaySeconds * 1000);

			subscribe('/modal/closed', module._onPopupClosed);
			$(selectors.cta).on('click', module._onCtaClick);

			let $countdown = $container.find(selectors.countdown);
			if ($countdown) {
				module._initCountdown($countdown);
			}
		}
	},

	_onPopupClosed: function ($modal) {
		if ($modal.is(selectors.container) === false) {
			return;
		}
		module._setCookie();
	},

	_onCtaClick: function () {
		module._setCookie();
	},

	_setCookie: function () {
		let $container = $(selectors.container);
		let id = $container.data('popup-id');

		//Cookies.set(id, true, { expires: 365, path: '/', secure: true });
		Cookies.set(id, true, { path: '/', secure: true });
	},

	_initCountdown: function($countdown) {
		var expiry = new Date($countdown.attr('data-expiry')).getTime();
		//console.log({
		//	expiry
		//});

		let updateCountdown = function() {
			let now = new Date().getTime();
			let delta = expiry - now;

			let days = Math.floor(delta / (1000 * 60 * 60 * 24));
			let hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			let minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));

			//console.log({
			//	now,
			//	delta,
			//	days,
			//	hours,
			//	minutes
			//});

			$countdown.find(selectors.days).text(days);
			$countdown.find(selectors.hours).text(hours);
			$countdown.find(selectors.mins).text(minutes);
		};
		var interval = setInterval(updateCountdown, 5000);
	}
};

export { module as PopupPromotion };