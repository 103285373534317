<script setup>
	import { defineProps, Static } from 'vue';
	import { modalStatus } from "../ref/refData";
	const props = defineProps({
		item: Object,
		index: Number,
		allotmentId: Number
	})
</script>

<template>

	<div class="c-modal js-modal" :id="'addCart-' + props.index + props.allotmentId" aria-modal="false" role="dialog" data-modal-body-open-class="c-modal__body-open--full">
		<div class="c-modal__body js-modal__body">
			<button class="c-modal__close t-link js-modal__close" aria-label="Close"></button>
			
			<div class="c-cart-added">
				<div class="c-cart-added__error-message" v-if="modalStatus.message">
					<span class="iconf-warning"></span>
					{{modalStatus.message}}
				</div>
				<div v-else>
					<div class="c-cart-added__confirm">
						<span class="iconf-tick c-cart-added__success"></span>
						<span>Added to your cart</span>
					</div>

					<div class="c-cart-added__item">
						<div class="c-cart-added__item-image">

							<picture v-if="props.item.image">
								<source :srcset="props.item.image.webpUrl" type="image/webp" />
								<img :src="props.item.image.url" />
							</picture>
						</div>
						<div class="c-cart-added__item-copy">
							<div class="c-cart-added__item-copy-wrapper">
								<div class="c-cart-added__item-titlebar">
									<div class="c-cart-added__item-heading">{{props.item.name}}</div>
									<div class="c-cart-added__item-location">{{props.item.parkName}}</div>
									<div v-if="props.item.urgencyText" class="c-cart-added__strapline mobile">{{props.item.urgencyText}}</div>
								</div>
								<div class="c-cart-added__details">
									<div class="c-cart-added__details-amounts">
										<div class="c-cart-added__details-persons">{{props.item.guest.adult}} Adults, {{props.item.guest.children}} Child, {{props.item.guest.infant}} Infant</div>
										<div class="c-cart-added__details-dates">{{format_date(props.item.date.start,"DD/MM/YYYY")}} - {{format_date(props.item.date.end, "DD/MM/YYYY")}}</div>
										<div class="c-cart-added__details-nights">{{props.item.nights}} Night</div>
									</div>
									<div class="c-cart-added__details-costs">
										<div class="c-cart-added__details-price">{{toNZD(props.item.totalAmount)}}</div>
										<div v-if="props.item.yourDiscountAmount" class="c-cart-added__details-save">Discount Applied {{toNZD(props.item.yourDiscountAmount)}}</div>
										<div v-if="props.item.potentialDiscountAmount" class="c-cart-added__details-save">Members save {{toNZD(props.item.potentialDiscountAmount)}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="c-cart-added__actions">
					<a href="#" class="c-cart-added__continue-button js-modal__close" aria-label="Close">Continue Shopping</a>
					<a class="c-cart-added__checkout-button" href="/cart/">View Cart & Check out</a>
				</div>

			</div>

		</div>
		<span class="c-modal__dummy" tabindex="0"><!-- Dummy focusable element --></span>
	</div>

</template>
<script>
	import moment from 'moment';

	export default {
		name: 'addedmodal',
		data() {
			return {}
		},
		methods: {

			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},
		}
	};
</script>