<script setup>
	import { editCart } from "../ref/refData";

</script>

<template>
	<div v-for="(cartitem, index) in cart" :id="cartitem.index">
		<div v-if="cartitem.warning" class="c-cart__item-warning">We're sorry, but the selected accommodation is not available for your chosen dates. Please try searching for different dates, or a different accommodation type.</div>
		<div class="c-cart-slideout__item">
			<div class="c-cart-slideout__item-copy">

				<div class="c-cart-slideout__item-location">{{cartitem.parkName}}</div>
				<div class="c-cart-slideout__item-heading">{{cartitem.name}}</div>
				<div v-if="cartitem.urgencyText" class="c-cart-slideout__strapline">{{cartitem.strapline}}</div>
				<div class="c-cart-slideout__details" v-if="cartitem.isAvailable">
					<div class="c-cart-slideout__details-amounts">
						<div class="c-cart-slideout__details-persons">
							{{cartitem.guest.adult}} Adults, {{cartitem.guest.children}} Child, {{cartitem.guest.infant}} Infant
						</div>
						<div class="c-cart-slideout__details-dates">{{format_date(cartitem.date.start, "DD/MM/YYYY")}} - {{format_date(cartitem.date.end, "DD/MM/YYYY")}}</div>
						<div class="c-cart-slideout__details-nights">{{cartitem.nights}} Nights</div>
					</div>
					<div class="c-cart-slideout__details-costs">
						<div class="c-cart-slideout__details-price">{{toNZD(cartitem.totalAmount + getTotalExtra(cartitem.chargeableExtras))}}</div>
						<p v-if="cartitem.yourDiscountAmount > 0" class="c-cart-slideout__details-save">Discount Applied {{ toNZD(cartitem.yourDiscountAmount) }}</p>
						<p v-if="cartitem.potentialDiscountAmount > 0" class="c-cart-slideout__details-save"> Members save {{ toNZD(cartitem.potentialDiscountAmount) }}</p>
					</div>
				</div>
				<div class="c-cart-slideout__item-unavailable" v-else>
					Unfortunately the item you have added is not available anymore.<br />Please add again.
				</div>
			</div>
			<div class="c-cart-slideout__item-actions">
				<div class="c-cart-slideout__item-actions-edit iconf-edit " aria-controls="editCart" @click="editCartHandler(cartitem)"></div>
				<div class="c-cart-slideout__item-actions-delete iconf-trash" @click="RemoveFromCart(cartitem.id)"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { CartHelper } from 'Util/cartHelper';
	import * as Core from 'Util/core';
	import moment from 'moment';

	export default {
		name: 'cartitemsmall',
		props: [
			"cart"
		],
		data() {
			return {

			}
		},
		mounted() {

		},
		methods: {
			editCartHandler: function (cartitem) {
				editCart.value = cartitem;
				editCart.value["isHidden"] = false;
				editCart.value["months"] = {
					current: this.selectedMonth(cartitem.date.start),
					next: this.nextMonth(cartitem.date.start),
					prev: this.prevMonth(cartitem.date.start)
				}

				document.querySelector("body")?.classList.add('c-modal__body-open--full');
			},

			RemoveFromCart: function (id) {

				var cartdata = JSON.parse(localStorage.getItem('cart'));

				cartdata = cartdata.filter(function (cartitem) {
					return cartitem.id !== id
				});

				if (confirm("Are you sure want to remove from the cart") == true) {

					CartHelper.saveCart(cartdata);

					window.dispatchEvent(new CustomEvent('cart-updated', {
						detail: {
							storage: JSON.parse(localStorage.getItem("cart"))
						}
					}));
				}
			},

			selectedMonth: function (date) {
				let now = new Date(date);
				let current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
				return current.toISOString().split('T')[0];
			},

			nextMonth: function (date) {
				let now = new Date(date);
				let nextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 1);
				return nextMonth.toISOString().split('T')[0];
			},

			prevMonth: function (date) {
				let now = new Date(date);
				let prevMonth = new Date(now.getFullYear(), now.getMonth(), 1);
				return prevMonth.toISOString().split('T')[0];
			},

			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},

			getTotalExtra: function (chargeableExtras) {

				let extras = chargeableExtras;

				let sumTotalExtra = extras.reduce((accumulator, object) => {
					return accumulator + object['bookedTotal'];
				}, 0);
				return sumTotalExtra;
			},

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},
		}
	};
</script>
