<script setup>
	import { defineProps, onMounted } from 'vue';
	import { availabilityData } from "../ref/refData";
	import { debounce } from "Vue/utils";

	const item = availabilityData;
	const props = defineProps({
		roomInfo: Object
	})

	let bookingPrice = () => {
		if (item.value.offers.length > 0) {
			const cheapestOffer = item.value.offers.reduce((prev, curr) => prev.totalAmount < curr.totalAmount ? prev : curr);
			return cheapestOffer.totalAmount;
		}
	}
</script>

<template>
	<div class="c-booking-panel" :aria-expanded="expanded">
		<div class="c-booking-panel-item-header" @click="clickHandler">
			<h3 class="c-booking-panel-item-heading">Book this <span v-if="item.offers !== null">from NZ{{toNZD(bookingPrice())}}</span>  <i class="c-booking-panel-item-arrow-icon iconf-small-arrow-down"></i></h3>
		</div>
		<div class="c-booking-panel-items-wrap">

			<div class="c-booking-panel-body">
				<div class="c-booking-panel__location">{{props.roomInfo.parkName}}</div>
				<h3 class="c-booking-panel__heading">{{props.roomInfo.roomType}}</h3>
				<div class="c-booking-panel-details">
					<p class="c-booking-panel-detail__person">
						{{item.guest.adult}} Adult, {{item.guest.children}} Child, {{item.guest.infant}} infant
						<button class="c-booking-panel-detail__edit" @click="item.setSelectedIndex(1), clickHandler(), scroller()"><i class="iconf-edit"></i>edit</button>
					</p>
					<p class="c-booking-panel-detail__date">
						{{format_date(item.date.start, 'DD/MM/YYYY')}} - {{format_date(item.date.end, 'DD/MM/YYYY')}}
						<button class="c-booking-panel-detail__edit" @click="item.setSelectedIndex(0), clickHandler(), scroller()"><i class="iconf-edit"></i>edit</button>
					</p>
					<p class="c-booking-panel-detail__night">{{item.nights}} Night</p>
				</div>
			</div>

			<offers-panel :booking-info="{
				image: props.roomInfo.image,
				hotelId: props.roomInfo.hotelId,
				roomId: props.roomInfo.roomId,
				parkName: props.roomInfo.parkName,
				isOffersLoaded: item.isOffersLoaded,
				date: {
					start: format_date(item.date.start,'YYYY-MM-DD'),
					end: format_date(item.date.end,'YYYY-MM-DD')
				},
				guest: {
					adult: item.guest.adult,
					children: item.guest.children,
					infant: item.guest.infant,
				},
				totalGuest: item.totalGuest,
				nights: item.nights,
				maxAdultOccupancy: item.maxAdultOccupancy,
				maxChildOccupancy: item.maxChildOccupancy,
				offers: item.offers,
				messages: item.messages
				}">
			</offers-panel>

		</div>
	</div>

</template>

<script>
	import moment from 'moment';

	export default {
		name: 'booking-panel',
		data() {
			return {
				expanded: false
			}
		},
		mounted() {
		},
		methods: {
			clickHandler: function () {
				this.expanded = !this.expanded;
			},

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},

			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},

			scroller: function () {

				const id = 'availabilityScroller';
				const yOffset = -50;
				const element = document.getElementById(id);
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

				window.scrollTo({ top: y, behavior: 'smooth' });
			}
		}
	};
</script>