<template>
	<div v-if="loading" class="c-cart__loader">
		<span class="c-cart__loader-message">Updating cart</span>
		<div class="loader">
			<span></span>
		</div>
	</div>
	<div class="c-checkout-sidebar c-booking-summary" v-if="cart">
		<div class="c-checkout-sidebar__header">
			Booking summary for NZ {{toNZD(totalPrice())}}
			<a class="iconf-edit t-link" :href="/cart/"></a>
		</div>
		<div v-for="(cartitem, index) in cart" :id="cartitem.index">
			<div class="c-cart-slideout__item">
				<div class="c-cart-slideout__item-copy">
					<div class="c-cart-slideout__item-location">{{cartitem.parkName}}</div>
					<div class="c-cart-slideout__item-heading">{{cartitem.name}}</div>
					<div v-if="cartitem.urgencyText" class="c-cart-slideout__strapline">{{cartitem.strapline}}</div>
					<div class="c-cart-slideout__details" v-if="cartitem.isAvailable">
						<div class="c-cart-slideout__details-amounts">
							<div class="c-cart-slideout__details-persons">
								{{cartitem.guest.adult}} Adults, {{cartitem.guest.children}} Child, {{cartitem.guest.infant}} Infant
							</div>
							<div class="c-cart-slideout__details-dates">{{format_date(cartitem.date.start, "DD/MM/YYYY")}} - {{format_date(cartitem.date.end, "DD/MM/YYYY")}}</div>
							<div class="c-cart-slideout__details-nights">{{cartitem.nights}} Nights</div>
						</div>
						<div class="c-cart-slideout__details-costs">
							<div class="c-cart-slideout__details-price">{{toNZD(cartitem.totalAmount)}}</div>
							<div v-if="cartitem.yourDiscountAmount" class="c-cart-slideout__details-save">Discount Applied {{toNZD(cartitem.yourDiscountAmount)}}</div>
							<div v-if="cartitem.potentialDiscountAmount" class="c-cart-slideout__details-save">Members save {{toNZD(cartitem.potentialDiscountAmount)}}</div>
						</div>
					</div>
					<div v-else class="c-cart-slideout__strapline error">
						Unfortunately the item you have added is not available anymore. Please add again.
					</div>
				</div>
			</div>
		</div>
		<div class="c-cart-slideout__item c-cart-slideout__item--membership" v-if="hasMembership">
			<div class="c-cart-slideout__item-copy">
				<div class="c-cart-slideout__item-heading">{{membership.Description}}</div>
				<div class="c-cart-slideout__details-price">{{toNZD(parseFloat(membership.Amount, 10))}}</div>
			</div>
		</div>
		<div class="c-cart-slideout__totals">
			<div class="c-cart-slideout__totals-price">
				Extra
				<span>{{toNZD(getTotalExtra())}}</span>
			</div>
			<div class="c-cart-slideout__totals-price" v-if="getTotalPrice('yourDiscountAmount') > 0">
				Discount Applied
				<span>{{toNZD(getTotalPrice('yourDiscountAmount'))}}</span>
			</div>
			<div class="c-cart-slideout__totals-price" v-if="getTotalPrice('potentialDiscountAmount') > 0">
				Member save
				<span>{{toNZD(getTotalPrice('potentialDiscountAmount'))}}</span>
			</div>
			<div class="c-cart-slideout__totals-total">
				Total price
				<span>{{toNZD(totalPrice())}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineProps } from 'vue';
	import * as Core from 'Util/core';
	import { CartHelper }  from 'Util/cartHelper';
	import moment from 'moment';

	export default {
		name: 'checkout-summary',
		props: {
			refreshApi: Boolean,
		},
		data() {
			return {
				loading: true,
				cart: null,
				cartEmpty: false,
				hasMembership: false,
				membership: {},
				refeshData: this.refreshApi,
			}
		},
		mounted() {

			this.refreshCart();

			if (Core.checkCookie('cart-buyMembership')) {
				this.hasMembership = true;
				this.membership = JSON.parse(decodeURIComponent(Core.getCookie('cart-buyMembership')));
			}

			window.addEventListener('cart-updated', (event) => {
				this.cart = event.detail.storage;
			});
		},
		methods: {

			refreshCart: function () {
				let i = 0;
				let cartJson = [];
				let refreshedCart = [];
				let memberEmail = '';
				const storedCart = localStorage.getItem('cart');

				if (storedCart) {
					cartJson = JSON.parse(storedCart);
				}

				if (!storedCart || cartJson.length === 0) {
					cartEmpty = true;
					loading = false;
					return;
				}

				if (document.getElementById('memberEmail')) {
					memberEmail = document.getElementById('memberEmail').dataset.memberEmail;
				}

				cartJson.forEach((cartItem) => {

					const pricingApiURL = `/api/hotels/${cartItem.hotelId}/rooms/${cartItem.roomTypeId}/pricing/${this.format_date(cartItem.date.start, 'YYYY-MM-DD')}/${this.format_date(cartItem.date.end, 'YYYY-MM-DD')}?adults=${cartItem.guest.adult}&children=${cartItem.guest.children}&infants=${cartItem.guest.infant}&promoCode=${cartItem.promoCode}&allotmentId=${cartItem.allotmentId}`;

					fetch(pricingApiURL, {
						method: 'GET'
					})
					.then(res => res.json())
					.then((res) => {

						let data = res.offers[0];

						if (data.dailyBreakdown) {
							cartItem.dailyBreakdown = data.dailyBreakdown;
						}

						cartItem.warning = false;
						cartItem.VoucherPaymentSelected = false;
						cartItem.memberEmail = memberEmail;
						cartItem.totalAmount = data.totalAmount;
						cartItem.potentialDiscountAmount = data.potentialDiscountAmount;
						cartItem.yourDiscountAmount = data.yourDiscountAmount;
						cartItem.isAvailable = data.isAvailable;
						cartItem.bookingDetail = data.bookingDetail;

						refreshedCart.push(cartItem);
					})
					.catch((error) => {
						console.log(error.toString());
						cartItem.warning = true;
						refreshedCart.push(cartItem);
					})
					.finally(() => {
						i++;

						if (i === cartJson.length) {
							refreshedCart.sort((a, b) => {
								const dateA = a.date.start;
								const dateB = b.date.start;

								if (dateA < dateB) {
									return -1;
								}
								if (dateA > dateB) {
									return 1;
								}
								return 0;
							});
							this.cart = refreshedCart;
							this.loading = false;

							CartHelper.saveCart(this.cart);

							window.dispatchEvent(new CustomEvent('cart-refreshed', {
								detail: {
									caller: 'checkoutSummary'
								}
							}));
						}
					});
				});
			},

			getTotalPrice: function (property) {
				let cartItems = this.cart;
				let sumTotalPrice = cartItems.reduce((accumulator, object) => {
					return accumulator + object[property];
				}, 0);
				return sumTotalPrice;
			},

			getTotalExtra: function () {
				let cartItems = this.cart;
				let extraPriceList = [];

				cartItems.forEach((c) => {
					let chargeableExtras = c.chargeableExtras;
					chargeableExtras.forEach((extra) => {
						extraPriceList.push(extra.bookedTotal)
					})
				});

				let sumTotalExtraPrice = extraPriceList.reduce((accumulator, a) => {
					return accumulator + a;
				}, 0);


				return sumTotalExtraPrice;
			},

			totalPrice() {
				let cartItems = this.cart;
				let totalChargePerBooking = [];

				cartItems.forEach((c) => {
					totalChargePerBooking.push(c.totalAmount);
					let chargeableExtras = c.chargeableExtras;

					chargeableExtras.forEach((extra) => {
						totalChargePerBooking.push(extra.bookedTotal)
					})
				})

				if (this.hasMembership) {
					totalChargePerBooking.push(this.membership.Amount);
				}

				let totalCharges = totalChargePerBooking.reduce((accumulator, a) => {
					return accumulator + a;
				}, 0);

				return totalCharges;
			},

			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			}
		}
	};
</script>
