import { ref } from 'vue';
import moment from 'moment';

// test data
export const shareData = ref({
    text: "test 123",
    isOpen: false,
    setIsOpen(value) {
        this.isOpen = value;
    }
});

const tomorrow = () => {
	// Get today's date
	let today = new Date();
	// Change the date by adding 1 to it (today + 1 = tomorrow)
	return today.setDate(today.getDate() + 1);
};

const currentMonth = () => {
	let now = new Date();
	let current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
	return current.toISOString().split('T')[0];
};

const nextMonth = () => {
	let now = new Date();
	let nextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 1);
	return nextMonth.toISOString().split('T')[0];
};

const prevMonth = () => {
	let now = new Date();
	let prevMonth = new Date(now.getFullYear(), now.getMonth(), 1);
	return prevMonth.toISOString().split('T')[0];
};

// Availability data
export const availabilityData = ref({
	isLoaded: false,
	isOffersLoaded: false,
	maxAdultOccupancy: '',
	maxChildOccupancy: '',
	date: {
		start: new Date(),
		end: new Date(tomorrow())
	},
	months: {
		current: currentMonth(),
		next: nextMonth(),
		prev: prevMonth()
	},
	selectedIndex: 0,
	setSelectedIndex(value) {
		this.selectedIndex = value;
	},
	hasValue: {
		calendar: true,
		guest: true
	},
	guest: {
		adult: 2,
		children: 0,
		infant: 0
	},
	totalGuest: 2,
	nights: 1,
	promoCode: '',
	offers: [],
	messages: null
});

export const modalStatus = ref({
	modalTrigger: '',
	isOpen: false,
	message: ''
});

export const editCart = ref({});