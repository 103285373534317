import $ from 'jquery';
import { MapLoad } from 'App/map/map.load';

import { createApp } from 'vue/dist/vue.esm-bundler.js'
import vueClickOutsideElement from 'vue-click-outside-element';

import BookingPanel from "Vue/components/bookingPanel/bookingPanel";
import OffersPanel from "Vue/components/offersPanel/offersPanel";
import AvailabilityGrid from "Vue/components/availabilityGrid/availabilityGrid";
import SlideoutCart from "Vue/components/slideoutCart/slideoutCart";
import CartItemSmall from "Vue/components/cartItemSmall/cartItemSmall";
import CartItemBig from "Vue/components/cartItemBig/cartItemBig";
import Cart from "Vue/components/Cart/Cart";
import EditCart from "Vue/components/editCart/editCart";
import AddCart from "Vue/components/addToCart/addToCart";
import AddModal from "Vue/components/addedModal/addedModal";
import InfoModal from "Vue/components/infoModal/infoModal";
import MiniCart from "Vue/components/miniCart/miniCart";
import Test from "Vue/components/test/test";

import ParkFinder from "Vue/components/parkFinder/parkFinder";
import Locations from "Vue/components/locations/locations";
import ParkListing from "Vue/components/parkListing/parkListing";
import Properties from "Vue/components/properties/properties";
import parkLocation from "Vue/components/parkLocation/parkLocation";

import CheckoutSummary from "Vue/components/checkoutSummary/checkoutSummary";
import AccommodationSearch from "Vue/components/accommodationSearch/accommodationSearch";

// create vue app
const MainComponents = createApp({});
const SideBarComponents = createApp({});
const SlideOutComponents = createApp({});
const CartComponents = createApp({});
const EditCartComponents = createApp({});
const AddCartComponents = createApp({});
const HeaderComponents = createApp({});
const ParkFinderComponents = createApp({});
const AccommodationSearchComponents = createApp({});
const SidebarAccommodationSearchComponents = createApp({});
const ParkLocationComponents = createApp({});

ParkFinderComponents.use(vueClickOutsideElement);

// register vue componenets to vue app
MainComponents.component('test', Test);
MainComponents.component('bookingPanel', BookingPanel);
MainComponents.component('offersPanel', OffersPanel);
MainComponents.component('availabilityGrid', AvailabilityGrid);
MainComponents.component('infoModal', InfoModal);
MainComponents.component('addtocart', AddCart);
MainComponents.component('addedmodal', AddModal);

SideBarComponents.component('bookingPanel', BookingPanel);
SideBarComponents.component('offersPanel', OffersPanel);
SideBarComponents.component('infoModal', InfoModal);
SideBarComponents.component('addtocart', AddCart);
SideBarComponents.component('addedmodal', AddModal);
SideBarComponents.component('checkoutSummary', CheckoutSummary);

SlideOutComponents.component('slideoutcart', SlideoutCart);
SlideOutComponents.component('cartitemsmall', CartItemSmall);
SlideOutComponents.component('editcart', EditCart);

CartComponents.component('cart', Cart);
CartComponents.component('cartitembig', CartItemBig);

EditCartComponents.component('editcart', EditCart);

AddCartComponents.component('addtocart', AddCart);
AddCartComponents.component('addedmodal', AddModal);

HeaderComponents.component('miniCart', MiniCart);

ParkFinderComponents.component('parkfinder', ParkFinder);
ParkFinderComponents.component('locations', Locations);
ParkFinderComponents.component('parklisting', ParkListing);
ParkFinderComponents.component('properties', Properties);
ParkFinderComponents.component('offersPanel', OffersPanel);
ParkFinderComponents.component('addtocart', AddCart);
ParkFinderComponents.component('addedmodal', AddModal);
ParkLocationComponents.component('locations', Locations);
ParkLocationComponents.component('parkLocation', parkLocation);


AccommodationSearchComponents.component('accommodationSearch', AccommodationSearch);
SidebarAccommodationSearchComponents.component('accommodationSearch', AccommodationSearch);

// Mounting the App

if ($(".js-header-cart").length > 0) {
	HeaderComponents.mount(".js-header-cart");
}

if ($(".js-accommodation-search").length > 0) {
	AccommodationSearchComponents.mount(".js-accommodation-search");
}

if ($(".js-accommodation-search__sidebar").length > 0) {
	SidebarAccommodationSearchComponents.mount(".js-accommodation-search__sidebar");
}

if ($(".js-main-components").length > 0) {
	MainComponents.mount(".js-main-components");
}

if ($(".js-sidebar-components").length > 0) {
	SideBarComponents.mount(".js-sidebar-components");
}

if ($(".js-cart-slideout").length > 0) {
	SlideOutComponents.mount(".js-cart-slideout");
}

if ($("#Cart").length > 0) {
	CartComponents.mount("#Cart");
}

if ($(".js-editcart").length > 0) {
	EditCartComponents.mount(".js-editcart");
}

if ($("#AddToCart").length > 0) {
	AddCartComponents.mount("#AddToCart");
}

if ($('#ParkFinder').length > 0) {
	ParkFinderComponents.mount("#ParkFinder");
}

if ($(".js-park-locations").length) {
	ParkLocationComponents.mount(".js-park-locations");
}