<script setup>
	import { CartHelper } from 'Util/cartHelper';
	import { ref, onBeforeMount, onMounted, watch } from 'vue';
	import { editCart } from "../ref/refData";

	const props = defineProps({
		cart: Object
	})

	let isRequired = false;

	onBeforeMount(() => {
		if (props.cart.vehicleDetail?.numberOfVehicles > 0) {
			isRequired = true
		} else {
			isRequired = false
		}
	});

	onMounted(() => {

		if (document.querySelector('.c-cart')) {

			const firstItem = document.getElementsByClassName('c-cart__item')[0];
			const expander = document.getElementsByClassName('c-cart-expander-button')[0];
			firstItem.setAttribute("aria-expanded", true);
			expander.setAttribute("aria-expanded", true);
		}
	});

	watch(() => props.cart.vehicleDetail?.numberOfVehicles, (newValue, oldValue) => {

		if (newValue > 0) {
			isRequired = true
		} else {
			isRequired = false
		}
	});

	let savedClass = ref('c-cart__save js-cart__save-btn');
	let saveBtnText = ref('Save');

	const updateSaveButton = () => {
		saveBtnText.value = 'Saved';
		savedClass.value += ' is-saved';

		setTimeout(function () {
			saveBtnText.value = 'Save';
			savedClass.value = 'c-cart__save js-cart__save-btn';
		}, 5000);
	};

	const save = (id) => {

		saveBtnText.value = 'Saving...';

		var cartdata = JSON.parse(localStorage.getItem('cart'));

		var editeditem = cartdata.filter(function (cart) {
			return cart.id === id
		});

		var restofcart = cartdata.filter(function (cart) {
			return cart.id !== id
		});

		editeditem[0] = props.cart;

		if (editeditem[0].chargeableExtras) {

			let extra = editeditem[0].chargeableExtras;
			let isMember = editeditem[0].bookingDetail.isMember;


			extra.forEach((e) => {
				switch (e.pricingBasis) {
					case "Each":
						if (isMember) {
							e.bookedTotal = e.priceOnMembers * e.eachCount;
						} else {
							e.bookedTotal = e.price * e.eachCount;
						}
						break;
					case "Per person per night":
						if (isMember) {
							e.bookedTotal = e.priceOnMembers * editeditem[0].totalGuest * editeditem[0].nights * parseInt(e.eachCount);
						} else {
							e.bookedTotal = e.price * (editeditem[0].guest.adult + editeditem[0].guest.children) * editeditem[0].nights * parseInt(e.eachCount);
						}
						break;
					case "Per booking":
						if (isMember) {
							e.bookedTotal = e.priceOnMembers * parseInt(e.eachCount);
						} else {
							e.bookedTotal = e.price * parseInt(e.eachCount);
						}

				}
			});

		}

		restofcart.push(editeditem[0]);

		restofcart.sort((a, b) => {
			const dateA = a.date.start;
			const dateB = b.date.start;

			if (dateA < dateB) {
				return -1;
			}
			if (dateA > dateB) {
				return 1;
			}
			return 0;
		});

		CartHelper.saveCart(restofcart);

		updateSaveButton();

		window.dispatchEvent(new CustomEvent('cart-updated', {
			detail: {
				storage: JSON.parse(localStorage.getItem("cart"))
			}
		}));
	}

	const validation = (e) => {

		if (e.target.hasAttribute('required')) {
			if (e.target.value == '' || e.target.value == null) {
				e.target.classList.add("error");
			} else {
				e.target.classList.remove("error");
			}
		}

	}

	const decrementer = (data) => {
		console.log(data);
	}

</script>

<template>
	<div class="c-cart__item js-expand-collapse allow-animations" aria-expanded="false" data-expand-collapse-animation="slideDown" :class="'cartItem-'+props.cart.id">
		<div v-if="props.cart.warning" class="c-cart__item-warning">We're sorry, but the selected accommodation is not available for your chosen dates. Please try searching for different dates, or a different accommodation type.</div>
		<div class="c-cart-top">
			<div class="c-cart__item-image">
				<picture v-if="props.cart.image">
					<source :srcset="props.cart.image.webpUrl" type="image/webp" />
					<img :src="props.cart.image.url" />
				</picture>
			</div>
			<div class="c-cart__item-copy">

				<div class="c-cart__item-copy-wrapper">
					<div class="c-cart__item-titlebar">
						<div class="c-cart__item-heading">{{props.cart.name}}</div>
						<div class="c-cart__item-location">{{props.cart.parkName}}</div>
						<div class="c-cart__strapline mobile">{{props.cart.strapline}}</div>
					</div>
					<div class="c-cart__details" v-if="props.cart.isAvailable">
						<div class="c-cart__details-amounts">
							<div class="c-cart__details-persons">{{props.cart.guest.adult}} Adults, {{props.cart.guest.children}} Children, {{props.cart.guest.infant}} Infants</div>
							<div class="c-cart__details-dates">{{format_date(props.cart.date.start, "DD/MM/YYYY")}} - {{format_date(props.cart.date.end, "DD/MM/YYYY")}}</div>
							<div class="c-cart__details-nights">{{props.cart.nights}} Nights</div>
						</div>
						<div class="c-cart__details-costs">
							<div class="c-cart__details-price">{{toNZD(props.cart.totalAmount + getTotalExtra(props.cart.chargeableExtras))}}</div>
							<div class="c-cart__details-save" v-if="props.cart.yourDiscountAmount > 0">Discount Applied {{toNZD(props.cart.yourDiscountAmount)}}</div>
							<div class="c-cart__details-save" v-if="props.cart.potentialDiscountAmount > 0">Members save {{toNZD(props.cart.potentialDiscountAmount)}}</div>
						</div>
					</div>
					<div v-else class="c-cart__item-unavailable">
						Unfortunately the item you have added is not available anymore.<br />Please add again.
					</div>
					<div class="c-cart__strapline desktop">{{props.cart.strapline}}</div>
				</div>
			</div>
			<div class="c-cart__item-actions">
				<div class="c-cart__item-actions-edit iconf-edit js-modal__trigger" aria-controls="editCart" @click="editCartHandler(props.cart)"></div>
				<div class="c-cart__item-actions-delete iconf-trash" @click="RemoveFromCart(props.cart.id)"></div>
			</div>
		</div>

		<div v-if="props.cart.isAvailable" class="c-cart-expander">
			<div class="c-expand-collapse__body js-expand-collapse__item-body">
				<form @submit.prevent="save(props.cart.id)" class="js-cart-item" :data-cartid="props.cart.id">
					<div class="c-form c-cart-expander-container">
						<div class="c-cart__additional-information">
							<div class="c-cart__line-header">
								Additional information
							</div>
							<div class="c-cart__additional-items">
								<div v-if="props.cart.dimensionsRequired">
									<div class="c-form__fields-set">
										<div class="c-form__field-group">
											<div class="c-form__field">
												<label class="c-form__label">Dimension for*</label>
												<select class="c-form__input js-form-required"
														v-model="props.cart.vehicleDetail.vanType"
														@change="validation"
														required>
													<option value="tent">Tent</option>
													<option value="caravan">Caravan</option>
													<option value="motorhome">Motorhome</option>
													<option value="camper-trailer">Camper trailer</option>
												</select>
											</div>
										</div>

										<div class="c-form__field-group c-form__field-group-two-col">
											<div class="c-form__field">
												<label class="c-form__label">Size*</label>
												<div class="c-form__doublebox">
													<input class="c-form__input js-form-required" placeholder="Length" type="number" v-model="props.cart.vehicleDetail.vanLength" min="0" />
													<span>X</span>
													<input class="c-form__input js-form-required" placeholder="Width" type="number" v-model="props.cart.vehicleDetail.vanWidth" min="0" />
												</div>
											</div>

											<div class="c-form__field">
												<label class="c-form__label">Unit*</label>
												<select class="c-form__input js-form-required"
														v-model="props.cart.vehicleDetail.vanUnitOfMeasurement"
														@change="validation"
														required>
													<option value="meter">Meters</option>
													<option value="feet">Feet</option>
												</select>
											</div>
										</div>
									</div>

									<div class="c-form__fields-set">
										<div class="c-form__field-group c-form__field-group-two-col">
											<div class="c-form__field">
												<label class="c-form__label">Number of vehicles*</label>
												<select class="c-form__input js-form-required"
														v-model="props.cart.vehicleDetail.numberOfVehicles"
														@change="validation"
														required>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</select>
											</div>

											<div class="c-form__field">
												<label class="c-form__label">Number of trailers*</label>
												<select class="c-form__input js-form-required"
														v-model="props.cart.vehicleDetail.numberOfTrailers"
														@change="validation"
														required>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</select>
											</div>
										</div>

										<div class="c-form__field-group">
											<div class="c-form__field">
												<label class="c-form__label">Vehicle Registration number {{isRequired ? '*' : ''}}</label>
												<input class="c-form__input"
													   :class="isRequired ? 'js-form-required' : ''"
													   style="text-transform:uppercase"
													   type="text"
													   name="registrationNumber"
													   v-model="props.cart.vehicleDetail.vehicleRegistrationNumber"
													   :required="!!isRequired"
													   @keyup="validation" />
											</div>
										</div>
									</div>
								</div>

								<div class="c-form__fields-set">
									<div class="c-form__field-group c-form__field-group-two-col">
										<div class="c-form__field">
											<label class="c-form__label">Estimate check in time*</label>
											<select class="c-form__input js-form-required"
													v-model="props.cart.additionalBookingDetail.eta"
													@change="validation"
													required>
												<option value="" disabled>Please select</option>
												<option value="14:00">14:00</option>
												<option value="15:00">15:00</option>
												<option value="16:00">16:00</option>
												<option value="17:00">17:00</option>
												<option value="18:00">18:00</option>
												<option value="19:00">19:00</option>
												<option value="20:00">20:00</option>
												<option value="21:00">21:00</option>
												<option value="22:00">22:00</option>
											</select>
										</div>
										<div class="c-form__field"></div>
									</div>
								</div>

								<div class="c-form__field">
									<label class="c-form__label">Special requests (Request cannot be guaranteed, but we will try our best)</label>
									<textarea class="c-form__input" :id="'comments-'+props.cart.id" v-model="props.cart.additionalBookingDetail.comments"></textarea>
								</div>
							</div>
							<div v-if=" props.cart.chargeableExtras">
								<div class="c-cart__line-header">
									Extra
								</div>
								<div class="c-cart__extra-items">
									<div v-for="extra in props.cart.chargeableExtras">
										<div class="c-cart__extra-row">
											<div class="c-cart__extra-copy">
												<span v-if="props.cart.bookingDetail?.isMember">
													{{extra.shortDescription}} <b>{{toNZD(extra.priceOnMembers)}}</b> {{extra.pricingBasis}}
												</span>
												<span v-else>
													{{extra.shortDescription}} <b>{{toNZD(extra.price)}}</b> {{extra.pricingBasis}}
												</span>

												<div class="c-cart__extra-tooltip-wrapper c-tooltip js-tooltip" v-if="extra.shortDescription != extra.longDescription">
													<div class="c-cart__tooltip js-tooltip__trigger">
														<span>i</span>
													</div>
													<div class="c-tooltip__body js-tooltip__body">
														<div class="c-tooltip__close iconf-close js-tooltip__close"></div>
														<div class="c-tooltip__content">
															{{extra.longDescription}}

															<div v-if="extra.linkUrl">
																<a :href="extra.linkUrl" target="_blank" class="t-link">{{extra.linkUrl}}</a>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="c-cart__extra-value">
												<div v-if="extra.pricingBasis == 'Per booking' || extra.pricingBasis == 'Per person per night'">
													<div class="c-form__field">
														<input class="c-form__input" v-model.number="extra.eachCount" type="checkbox" true-value="1" false-value="0" :checked="((extra.eachCount)? 'checked': '')" />
														<span v-if="props.cart.bookingDetail.isMember">
															<label class="c-form__label">{{toNZD(extra.priceOnMembers)}}</label>
														</span>
														<span v-else>
															<label class="c-form__label">{{toNZD(extra.price)}}</label>
														</span>
													</div>
												</div>
												<div v-else-if="extra.pricingBasis == 'Booking Percentage'">
													Calculation missing
												</div>
												<div v-else>
													<div class="c-cart__extra-value">
														<div class="c-form__field c-form__number-wrapper">
															<a class="decrementer" @click.stop.prevent="extra.eachCount--, ((extra.eachCount <= 0) ? extra.eachCount = 0: extra.eachCount)"><span class="iconf-minus"></span></a>
															<span class="c-form__input-wrap">
																<input v-model.number="extra.eachCount" type="number" min="0" />
																<span v-if="props.cart.bookingDetail.isMember">
																	<label class="c-form__label--price">{{toNZD(extra.priceOnMembers * extra.eachCount)}}</label>
																</span>
																<span v-else>
																	<label class="c-form__label--price">{{toNZD(extra.price * extra.eachCount)}}</label>
																</span>
															</span>
															<a class="incrementer" @click.stop.prevent="extra.eachCount++"><span class="iconf-plus"></span></a>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="c-cart__price-breakdown">
							<div class="c-cart__line-header">
								Price breakdown
							</div>
							<div class="c-cart__price-body">
								<div class="c-cart-price__listing">
									<div class="c-cart-price__listing-item c-cart-price__listing-total">
										Accommodation<span>{{toNZD(props.cart.totalAmount)}}</span>
									</div>
									<div v-for="day in props.cart.dailyBreakdown" class="c-cart-price__listing-item">
										{{format_date(day.date, "ddd DD MMMM YYYY")}}<span>{{toNZD(day.amount)}}</span>
									</div>
									<div class="c-cart-price__listing-item c-cart-price__listing-item-discount" v-if="props.cart.yourDiscountAmount > 0">Discount <span>- {{toNZD(props.cart.yourDiscountAmount)}}</span></div>

									<div class="c-cart-price__listing-item c-cart-price__listing-total">
										Extras <span>{{toNZD(getTotalExtra(props.cart.chargeableExtras))}}</span>
									</div>
									<div v-for="extra in props.cart.chargeableExtras" class="">
										<div v-if="extra.bookedTotal > 0" class="c-cart-price__listing-item">
											{{extra.shortDescription}}<span>{{toNZD(extra.bookedTotal)}}</span>
										</div>
									</div>


								</div>
							</div>
						</div>
					</div>
					<div class="c-cart__action">
						<button :class="savedClass">{{saveBtnText}} <span class="c-cart__save-icon iconf-tick" aria-hidden="true"></span></button>
					</div>
				</form>

			</div>
		</div>

		<div v-if="props.cart.isAvailable" class="c-cart-expander-button c-expand-collapse__trigger js-expand-collapse__toggle" aria-controls="expander-" aria-expanded="false">
			<span class="c-cart-expander-close iconf-minus"></span>
			<span class="c-cart-expander-open iconf-plus"></span>
		</div>
	</div>

</template>

<script>
	import moment from 'moment';
	export default {
		name: 'cartitembig',
		props: [
			"cart"
		],
		data() {
			return {
			}
		},
		methods: {

			editCartHandler: function (cartitem) {
				//console.log(cartitem.id)

				editCart.value = cartitem;
				editCart.value["isHidden"] = false;
				editCart.value["months"] = {
					current: this.selectedMonth(cartitem.date.start),
					next: this.nextMonth(cartitem.date.start),
					prev: this.prevMonth(cartitem.date.start)
				}

				//console.log(cartitem)
				document.querySelector("body")?.classList.add('c-modal__body-open--full');
			},

			RemoveFromCart: function (id) {

				var cartdata = JSON.parse(localStorage.getItem('cart'));

				cartdata = cartdata.filter(function (cart) {
					return cart.id !== id
				});

				if (confirm("Are you sure want to remove from the cart") == true) {
					localStorage.setItem("cart", JSON.stringify(cartdata))

					window.dispatchEvent(new CustomEvent('cart-updated', {
						detail: {
							storage: JSON.parse(localStorage.getItem("cart"))
						}
					}));
				}

				location.reload();
			},

			selectedMonth: function (date) {
				let now = new Date(date);
				let current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
				return current.toISOString().split('T')[0];
			},

			nextMonth: function (date) {
				let now = new Date(date);
				let nextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 1);
				return nextMonth.toISOString().split('T')[0];
			},

			prevMonth: function (date) {
				let now = new Date(date);
				let prevMonth = new Date(now.getFullYear(), now.getMonth(), 1);
				return prevMonth.toISOString().split('T')[0];
			},

			getTotalPrice: function (property) {
				let cartItems = this.cart;
				let sumTotalPrice = cartItems.reduce((accumulator, object) => {
					return accumulator + object[property];
				}, 0);
				return sumTotalPrice;
			},

			getTotalExtra: function (chargeableExtras) {

				let extras = chargeableExtras;

				let sumTotalExtra = extras.reduce((accumulator, object) => {
					return accumulator + object['bookedTotal'];
				}, 0);
				return sumTotalExtra;
			},

			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},
		}
	};
</script>
