<template>
	<div class="c-park-locations__map" style="max-width:100%;overflow:hidden;width:100%;height:100%;">
		<locations :MapData="locationList" :ParkFinder="false"></locations>
	</div>
</template>

<script>
	//import { ref } from 'vue';
	export default {
		name: 'park-location',
		data() {
			return {
				locationList: [],
			}
		},
		mounted: function () {
			const ParksAPI = `/api/parks`;

			fetch(ParksAPI, {
				method: 'GET'
			})
				.then(res => res.json())
				.then((res) => {
					if (res) {
						this.locationList = res;
					}
				})
				.catch(error => console.log(error.toString()))
		}
	};
</script>
