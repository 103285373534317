/* util/core */

import $ from 'jquery';

var scrollTimeout = null;
var scrollSpeedMult = 2;
var scrollSpeedMax = 600;

var module = {

	scrollToSelector: function(selector, pxAbove) {
		module.scrollToElm($(selector), pxAbove);
	},
	// Scroll to an element on screen
	// $elm = jQuery element - the element to scroll to
	// pxAbove = int - padding between top of screen and element (defaults to 0)
	scrollToElm: function( $elm, pxAbove ) {
		// Use timoeut to prevent queueing multiple animations
		if (scrollTimeout === null) {
			var topPadding = pxAbove || 0;
			var scrollHeight = $elm.offset().top;

			var currentScrollHeight = $(document).scrollTop();
			var targetScrollHeight = scrollHeight - topPadding;
			var scrollHeightDiff = Math.abs(currentScrollHeight - targetScrollHeight);

			// Travel faster to nearer elements, but never take longer than 600 ms
			var scrollSpeed = Math.min(scrollSpeedMult * scrollHeightDiff, scrollSpeedMax);

			if (targetScrollHeight !== currentScrollHeight) {
				$( 'html, body' ).animate( { scrollTop: targetScrollHeight }, scrollSpeed );
				scrollTimeout = window.setTimeout(module._clearScrollTimeout, scrollSpeed);
			}
		}
	},

	_clearScrollTimeout: function () {
		scrollTimeout = null;
	},

	// Returns a class name with no '.'
	// selector = string - the jQuery selector to remove '.' from
	getClassName: function( selector ) {
		return selector.replace( '.', '' );
	},

	// Returns an object containing the current URL's query string
	// Combines values with the same key
	// e.g. 'filter-content-type=photos,videos' instead of form.serialize which will return 'filter-content-type=photos&filter-content-type=videos'
	getQueryStringObject: function (queryString) {
		queryString = typeof queryString !== 'undefined' ? queryString : document.location.search.substr(1);
		queryString = queryString.split('&');

		var query = {};

		for (var i = 0; i < queryString.length; i++) {
			queryString[i] = queryString[i].split('=');

			if (queryString[i].length > 1) {
				var key = decodeURIComponent(queryString[i][0]);
				var val = decodeURIComponent(queryString[i][1]);

				if (query[key]) {
					query[key] += ',' + val;
				} else {
					query[key] = val;
				}
			}
		}

		return query;
	},

	setCookie: function(cname, cvalue, exdays) {
		const d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		let expires = 'expires=' + d.toUTCString();
		document.cookie = cname + '=' + encodeURIComponent(cvalue) + ';' + expires + ';path=/';
	},

	getCookie: function(cname) {
		let name = cname + '=';
		let ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	},

	checkCookie: function(cname) {
		let thisCookie = module.getCookie(cname);

		if (thisCookie !== '') {
			return true;
		} else {
			return false;
		}
	},

	deleteCookie: function (cname) {
		let thisCookie = module.getCookie(cname);

		if (thisCookie !== '') {
			module.setCookie(cname, '', -1);
		}
	},

	sanitiseParameter: function(param) {
		let newParam = encodeURIComponent(param);
		newParam = newParam.replace(/%20/g, ' ');
		return newParam;
	}
};

var scrollToSelector = module.scrollToSelector;
var scrollToElm = module.scrollToElm;
var getClassName = module.getClassName;
var getQueryStringObject = module.getQueryStringObject;
var getCookie = module.getCookie;
var setCookie = module.setCookie;
var checkCookie = module.checkCookie;
var deleteCookie = module.deleteCookie;
var sanitiseParameter = module.sanitiseParameter;

/**
 * listen - Bind an event listener to one or more elements
 *
 * @param  {string} selector - The selector for elements that need the event bound
 * @param  {string} event - The event to listen for
 * @param  {(Event) => void} callback - The function to call when the event happens
 *
 * @return {void}
 */
const listen = (selector, event, callback) => {
	document.querySelectorAll(selector).forEach(($el) => $el.addEventListener(event, callback));
};

/**
 * unlisten - Remove an event listener from one or more elements
 *
 * @param  {string} selector - The selector for elements that need the event unbound
 * @param  {string} event - The event to stop listening for
 * @param  {(Event) => void} callback - The function to unbind from the event
 *
 * @return {[type]} [description]
 */
const unlisten = (selector, event, callback) => {
	document.querySelectorAll(selector).forEach(($el) => $el.removeEventListener(event, callback));
};

export {
	scrollToSelector,
	scrollToElm,
	getClassName,
	getQueryStringObject,
	getCookie,
	setCookie,
	checkCookie,
	deleteCookie,
	listen,
	unlisten,
	sanitiseParameter
};
