<template>
	<div v-click-outside-element="outsidePanelEvent">
		<div class="c-park-finder-pod">
			<a class="c-park-finder-pod__image o-tile" :href="park.url">
				<picture v-if="park.image">
					<source :srcset="park.image.webpUrl" type="image/webp" />
					<img :src="park.image.url" class="o-tile__image" />
				</picture>
				<div class="c-park-finder-pod__region" :class="park.segment">
					<img :src="park.regionSvgPath" alt="park.regionName" />
				</div>
			</a>
			<div class="c-park-finder-pod__details">
				<a :href="tag" class="c-park-finder-pod__details-tag" :class="park.segment">
					<span class="c-park-finder-pod__details-icon">
						<img :src="'/content/images/icons/info.svg'" alt="" />
					</span>
					{{park.segment}}
				</a>
				<div class="c-park-finder-pod__details-wrapper">
					<div class="c-park-finder-pod__details-property-name">
						<a class="c-park-finder-pod__details-name" :href="park.url">
							{{park.name}}<br />
							TOP 10 Holiday Park
						</a>
						<div class="c-park-finder-pod__details-location">
							{{park.regionName}}
						</div>
					</div>
					<div class="c-park-finder-pod__details-stats" v-if="park.reviewProScore">
						<div class="c-park-finder-pod__tooltip-wrapper c-tooltip js-tooltip">
							<div class="c-park-finder-pod__tooltip js-tooltip__trigger"><span>i</span></div>

							<div class="c-tooltip__body js-tooltip__body">
								<div class="c-tooltip__close iconf-close js-tooltip__close"></div>
								<div class="c-tooltip__content">
									<h3>ReviewPro</h3>
									<p>
										The Guest Rating Score™ (GRS™) is an independent assessment of this hotel's quality rating based upon consumer reviews from around the internet. Analyzing more than 100 million consumer reviews in 40 languages from more than 100 leading online travel agencies and review sites.
									</p>
									<p>
										Provided by <a href="https://www.reviewpro.com/" target="_blank">ReviewPro</a> the leading provider of online reputation management analytics for the hotel sector, the GRS™ offers a complete and unbiased measurement of this hotel's online reputation.
									</p>
								</div>
							</div>

						</div>
						<div class="c-park-finder-pod__score-wrapper">
							<div class="c-park-finder-pod__score">{{park.reviewProScore}}</div>
							<div class="c-park-finder-pod__benchmark">/10</div>
						</div>
						<div class="c-park-finder-pod__score-text">
							Guest Rating Score
						</div>
						<div class="c-park-finder-pod__score-rating-link">
							<a>
								<img :src="'/content/images/interface/reviewpro.svg'" alt="review pro logo" />
							</a>
						</div>
					</div>
				</div>
				<div class="c-park-finder-pod__details-buttons">
					<button class="c-park-finder-pod__site-button" @click="openDetailsPanel(), selectType(type.name), updateRooms()" :class="{ active: type.name === activeType}" v-for="(type, i) in park.accommodationTypes">{{type.name}}</button>
				</div>
			</div>
		</div>

		<!--Details Panel-->
		<div class="c-park-finder-details" v-if="detailsPanel">
			<div class="c-park-finder-details__top-bar">
				<div class="c-park-finder-details__name-bar">
					{{park.name}}<br />
					TOP 10 Holiday Park
				</div>
				<div class="c-park-finder-details__button-bar">
					<button class="c-park-finder-details__site-button" @click="selectType(type.name), updateRooms(), doStuff()" :class="{ active: type.name === activeType}" v-for="(type, i) in park.accommodationTypes">{{type.name}}</button>
				</div>
				<a title="Close" class="c-park-finder-details-panel__close iconf-close" @click="closeDetailsPanel()"></a>
			</div>
			<div class="c-park-finder-details__filters">
				<div class="c-basic-filter">
					<div class="c-basic-filter__item">
						<div class="c-park-finder-details__filter-icon iconf-filter"></div>
						<div class="c-basic-filter__button" @click="toggleFilter(null), updateRooms()" :class="{ active: activeFilters.length === 0, firstload: firstload }">All</div>
						<div class="c-basic-filter__button" @click="toggleFilter(category.name), updateRooms()" :class="{ active: activeFilters && activeFilters.indexOf(category.name) !== -1 }" v-for="(category, i) in park.roomCategories">{{category.name}}</div>
					</div>
				</div>
			</div>

			<div class="c-park-finder-details__results" v-if="filteredRooms && filteredRooms.length > 0">

				<div v-if="loading" class="c-park-finder-details__loader">
					<div class="loader">
						<span></span>
					</div>
				</div>

				<Suspense>
					<template v-if="filteredRooms">
						<properties v-bind:property="filteredRooms" :room-info="park" :availability-data="availabilityData" :key="propertiesKey"></properties>
					</template>
				</Suspense>
			</div>

			<div class="c-park-finder__empty" v-else>
				<div class="c-park-finder__empty-wrapper">
					<div class="c-park-finder__empty-image">
						<img :src="'/content/images/icons/map-marker-2-128.png'" />
					</div>
					<div class="c-park-finder__empty-copy">
						There are no results for your selection.
					</div>
				</div>
			</div>

		</div>
	</div>

</template>

<script>
	import { scrollToSelector } from "../../../util/core";
	import properties from "../properties/properties";

	export default {
		name: 'parklisting',
		components: {
			properties,
		},
		props: [
			"park",
			"availabilityData",
			"tag"
		],
		data() {
			return {
				loading: false,
				detailsPanel: false,
				filteredRooms: [],
				typeOfPark: null,
				activeType: null,
				activeFilters: [],
				firstload: true,
				propertiesKey: 1
			}
		},
		mounted() {

		},
		methods: {

			openDetailsPanel() {

				this.doStuff();
				this.detailsPanel = true;
				this.propertiesKey++;

				document.body.classList.add('panel-open');
				scrollToSelector('#ParkFinder', 75);
				
			},

			closeDetailsPanel() {

				this.detailsPanel = false;
				this.activeType = false;
				this.activeFilters = [];

				document.body.classList.remove('panel-open');
			},

			outsidePanelEvent() {

				if (this.detailsPanel == true) {

					this.detailsPanel = false;
					this.activeType = false;
					this.activeFilters = [];

					document.body.classList.remove('panel-open');
				}
			},

			selectType(typeName) {
				this.activeType = typeName;
			},

			toggleFilter(filterName) {
				if (filterName !== null) {
					if (this.activeFilters.indexOf(filterName) === -1) {
						this.activeFilters.push(filterName);
					} else {
						this.activeFilters = this.activeFilters.filter(x => x !== filterName);
					}
				} else {
					this.activeFilters = [];
				}
				this.firstload = false;
			},

			matchesFilters(room) {

				if (this.activeFilters.length === 0) {
					return true;
				}

				for (var i = 0, l = this.activeFilters.length; i < l; i++) {

					var cat = this.activeFilters[i];

					if (room.facilityCategories.filter(el => el.name === cat).length === 0) {
						return false;
					}
				}

				return true;
			},

			updateRooms() {

				var rooms = this.park.rooms;
				this.typeOfPark = this.activeType;
				this.propertiesKey++;

				if (this.activeType) {
					rooms = rooms.filter(item => item.types.find(element => element.name === this.activeType));
				}

				if (this.activeFilters.length) {
					rooms = rooms.filter(item => this.matchesFilters(item));
				}

				this.filteredRooms = rooms;

			},

			doStuff: function () {

				this.loading = true;

				setTimeout(() => {
					this.loading = false;
				}, 500)
			}
		},
		created: function () {
			/*this.updateRooms();*/
		}
	};
</script>
