<script setup>
	import { ref, onMounted, onBeforeMount, onBeforeUpdate, onUpdated, defineProps, watch } from 'vue';
	import { availabilityData } from "../ref/refData";
	import { debounce } from "Vue/utils";
	import { useScreens } from 'vue-screen-utils';
	import { CartHelper } from 'Util/cartHelper';
	import moment from 'moment';

	const { mapCurrent } = useScreens({ xs: '0px', sm: '640px', md: '768px', lg: '1024px', xl: '1240px' });
	const columns = mapCurrent({ xl: 2, lg: 1, md: 2, sm: 1 });

	let data = availabilityData;

	const url = new URL(window.location);
	const query = window.location.search;

	let startDate;
	let endDate;
	let adult;
	let children;
	let infant;
	let promoCode;

	const bookingParams = localStorage.getItem('bookingParams');

	if (bookingParams !== null && bookingParams !== '') {
		const bpJson = JSON.parse(bookingParams);

		if (typeof bpJson === 'object') {
			startDate = bpJson.date.start;
			endDate = bpJson.date.end;
			adult = bpJson.guest.adult;
			children = bpJson.guest.children;
			infant = bpJson.guest.infant;
			promoCode = bpJson.promoCode;
		}
	}

	// Google Booking Parameters
	if (query.includes('checkIn') && query.includes('checkOut')) {
		const gbpCheckIn = url.searchParams.get('checkIn');
		const gbpCheckOut = url.searchParams.get('checkOut');
		const gbpAdults = url.searchParams.get('adults');
		const gbpChildren = url.searchParams.get('children');
		const gbpPromo = url.searchParams.get('promo');

		if (gbpCheckIn !== null && gbpCheckIn.length > 0) {
			startDate = gbpCheckIn;
		}

		if (gbpCheckOut !== null && gbpCheckOut.length > 0) {
			endDate = gbpCheckOut;
		}

		if (gbpAdults !== null && gbpAdults.length > 0) {
			adult = gbpAdults;
		}

		if (gbpChildren !== null && gbpChildren.length > 0) {
			children = gbpChildren;
		}

		infant = 0;

		if (gbpPromo !== null && gbpPromo.length > 0) {
			promoCode = gbpPromo;
		}
	}

	if (typeof startDate !== 'undefined') {
		data.value.date.start = new Date(startDate);
	}

	if (typeof endDate !== 'undefined') {
		data.value.date.end = new Date(endDate);
	}

	if (typeof adult !== 'undefined') {
		data.value.guest.adult = adult;
	}

	if (typeof children !== 'undefined') {
		data.value.guest.children = children;
	}

	if (typeof infant !== 'undefined') {
		data.value.guest.infant = infant;
	}

	if (typeof promoCode !== 'undefined') {
		data.value.promoCode = promoCode;
	}

	// define props
	const props = defineProps({
		roomInfo: Object
	})

	// calculate yesterday date
	const yesterday = () => {
		let today = new Date();
		return today.setDate(today.getDate() - 1);
	}

	// define attrs reference
	const attrs = ref([]);

	// define disabledDates reference - disable to select past dates
	const disabledDates = ref([
		{
			start: null, end: new Date(yesterday())
		}
	])

	const loadAgain = () => {

		let dateRecieved = data.value.date.start;

		let nextMonth = new Date(dateRecieved.getFullYear(), dateRecieved.getMonth() + 2, 1);
		let currentMonth = format_date(data.value.months.current, "YYYY-MM");

		const availabilityApiURL = `/api/hotels/${props.roomInfo.hotelId}/rooms/${props.roomInfo.roomId}/availability/${format_date(currentMonth, 'YYYY-MM')}/${format_date(nextMonth, 'YYYY-MM-DD')}`;

		checkAvailability(availabilityApiURL);

	}

	// onBeforeMount
	onBeforeMount(() => {
		// display loading animation
		data.value.isOffersLoaded = false;

		loadAgain();

		// get offer vai API
		getOffer();

	})

	// get offer when date updated
	watch(() => data.value.date, (oldValue, newValue) => {
		getOffer();
	});

	onBeforeUpdate(() => {
		// update number of nights
		let dateStart = data.value.date.start;
		let dateEnd = data.value.date.end;
		let timeDiff = Math.abs(dateStart.getTime() - dateEnd.getTime());
		let numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

		data.value.nights = numberOfNights;

		// update total num of guest
		data.value.totalGuest = getTotalGuest(data.value.guest);
	});

	// get total num of guest
	const getTotalGuest = (obj) => {
		return Object.values(obj).reduce((a, b) => a + b, 0);
	}

	// refresh and update availability and offer data
	const changeMonth = (e) => {

		let currentMonth = format_date(data.value.months.current, "YYYY-MM");
		let prevMonth = format_date(data.value.months.prev, "YYYY-MM");
		let nextMonth;
		let getNextMonth;
		let startDate;

		if (!(prevMonth < e[0].id)) {
			return;
		}

		if (currentMonth == e[0].id) {
			let today = new Date();
			today.setDate(today.getDate());
			startDate = format_date(today, 'YYYY-MM-DD');
		} else {
			startDate = format_date(e[0].id, 'YYYY-MM');
		}

		if (typeof e[1] !== 'undefined') {
			getNextMonth = new Date(e[1].id);
		} else {
			getNextMonth = new Date(e[0].id);
		}

		nextMonth = new Date(getNextMonth.getFullYear(), getNextMonth.getMonth() + 1, 1);

		const availabilityApiURL = `/api/hotels/${props.roomInfo.hotelId}/rooms/${props.roomInfo.roomId}/availability/${format_date(startDate, 'YYYY-MM')}/${format_date(nextMonth, 'YYYY-MM')}`;

		checkAvailability(availabilityApiURL);
	}

	/**
	 *  update date format
	 * @param value - date value
	 * @param formatType - date format type e.g YYYY-MM-DD, DD/MM/YYYY
	 * @returns {string}
	 */
	const format_date = (value, formatType) => {
		return moment(value).format(formatType)
	}

	// get offers for API
	const getOffer = () => {
		// define pricing API enpoint URL based on the props
		const pricingApiURL = `/api/hotels/${props.roomInfo.hotelId}/rooms/${props.roomInfo.roomId}/pricing/${format_date(data.value.date.start, 'YYYY-MM-DD')}/${format_date(data.value.date.end, 'YYYY-MM-DD')}?adults=${data.value.guest.adult}&children=${data.value.guest.children}&infants=${data.value.guest.infant}&promoCode=${data.value.promoCode}&explain=true`;

		// display loading animation
		data.value.isOffersLoaded = false;
		localStorage.setItem('bookingParams', JSON.stringify(data.value));

		fetch(pricingApiURL, {
			method: 'GET'
		})
			.then(res => res.json())
			.then((res) => {
				// hide loading animation
				data.value.isOffersLoaded = true;
				// update availabilityData ref offers data
				data.value.offers = res.offers;
				data.value.messages = res.messages;
			})
			.catch((error) => {
				console.log(error.toString())
				// hide loading animation
				data.value.isOffersLoaded = true;
			})
	}

	const checkAvailability = (url) => {
		// get Availability data via API
		fetch(url, {
			method: 'GET'
		})
			.then(res => res.json())
			.then((res) => {
				// hide loading anumation
				data.value.isLoaded = true;
				// update maxAdultOccupancy
				data.value.maxAdultOccupancy = res.maxAdultOccupancy;
				// update maxChildOccupancy
				data.value.maxChildOccupancy = res.maxChildOccupancy;

				if (res) {
					// define variables
					let dates = res.days;
					let availableDays = [];
					let availableWithMlos = [];
					let unavailableWithMlos = [];
					let unavailableForCheckIn = [];
					let availableForCheckOutOnly = [];

					// find unavailable Dates
					let unavailableDates = dates.filter(el => el.statusDescription === "Unavailable");

					unavailableDates.forEach((day) => {
						disabledDates.value.push({ start: day.date, end: day.date })
					})

					// find available Dates
					let availableDates = dates.filter(el => el.statusDescription === "Available");

					availableDates.forEach((day) => availableDays.push(new Date(day.date)));

					// find available with MLOS dates
					let availableWithMlosDates = dates.filter(el => el.statusDescription === "AvailableWithMlos");

					availableWithMlosDates.forEach((day) => availableWithMlos.push(new Date(day.date)));

					// find available with MLOS dates
					let unavailableWithMlosDates = dates.filter(el => el.statusDescription === "UnavailableForCheckInWithMlos");

					unavailableWithMlosDates.forEach((day) => unavailableWithMlos.push(new Date(day.date)));

					// find unavailable For CheckIn Dates
					let unavailableForCheckInDates = dates.filter(el => el.statusDescription === "UnavailableForCheckIn");

					unavailableForCheckInDates.forEach((day) => unavailableForCheckIn.push(new Date(day.date)));

					// find available For CheckOut Only Dates
					let availableForCheckOutOnlyDates = dates.filter(el => el.statusDescription === "AvailableForCheckOutOnly");

					availableForCheckOutOnlyDates.forEach((day) => availableForCheckOutOnly.push(new Date(day.date)));

					// define calendar Attributes data based on Availabilities date
					// customise calender style based on dates more info https://vcalendar.io/calendar/attributes.html#colors
					let calendarAttributes = [
						{
							key: 'available',
							highlight: {
								color: 'available',
							},
							dates: availableDays
						},
						{
							key: 'availableWithMlos',
							highlight: {
								color: 'available-mlos',
							},
							dates: availableWithMlos
						},
						{
							key: 'unavailableWithMlos',
							highlight: {
								color: 'unavailable-mlos',
							},
							dates: unavailableWithMlos
						},
						{
							key: 'unavailableForCheckIn',
							highlight: {
								color: 'unavailable-checkin',
							},
							dates: unavailableForCheckIn
						},
						{
							key: 'availableForCheckOutOnly',
							highlight: {
								color: 'available-checkout-only',
							},
							dates: availableForCheckOutOnly
						}
					];
					// update attrs ref data
					attrs.value = calendarAttributes;

				}

			})
			.catch((error) => {
				data.value.isLoaded = true;
				data.value.isOffersLoaded = true;
				console.log(error.toString())
			})
	}
</script>

<template>
	<div v-if="!data.isLoaded">
		<div class="loader">
			<span></span>
		</div>
	</div>
	<div v-else class="c-room-details__availability-wrap">

		<div class="c-room-details__availability-steps">
			<div class="c-listing__layout">
				<h2 class="c-listing__heading">Availability</h2>

				<div class="c-basic-filter c-room-details__availability-filters">
					<div class="c-basic-filter__item">
						<div class="c-basic-filter__button"
							 :aria-current="data.hasValue.calendar"
							 @click="data.setSelectedIndex(0)">
							{{format_date(data.date.start, 'DD MMM')}} - {{format_date(data.date.end, 'DD MMM')}}
						</div>
					</div>
					<div class="c-basic-filter__item">
						<div class="c-basic-filter__button"
							 :aria-current="data.hasValue.guest"
							 @click="data.setSelectedIndex(1)">{{data.totalGuest}} Guest</div>
					</div>
					<div class="c-basic-filter__item">
						<div class="c-basic-filter__button"
							 :aria-current="data.promoCode !== null && data.promoCode !== ''"
							 @click="data.setSelectedIndex(2)">Promo code</div>
					</div>
				</div>
			</div>
		</div>
		<div class="c-room-details__availability__body-wrap">
			<div v-if="data.selectedIndex == 0">
				<div class="c-room-details__availability__body">
					<div class="c-room-details__availability__body-content">
						<p>Please select check in and check out dates</p>
						<div class="c-room-details__availability-calender">
							<DatePicker trim-weeks
										:first-day-of-week="2"
										v-model="data.date"
										id="AvailabilityCalender"
										step="1"
										:min-date="new Date()"
										:columns="columns"
										:attributes="attrs"
										:disabled-dates="disabledDates"
										v-model.range="range"
										@did-move="changeMonth" />
						</div>
						<div class="c-room-details__availability-legend">
							<div class="c-room-details__availability-legend-available">
								<span></span>
								Available
							</div>
							<div class="c-room-details__availability-legend-available-mlos">
								<span></span>
								Minimum Length of Stay Applies
							</div>
							<div class="c-room-details__availability-legend-unavailable-checkin">
								<span></span>
								Unavailable for Check In
							</div>
							<div class="c-room-details__availability-legend-unavailable">
								<span></span>
								Unavailable
							</div>
							<div class="c-room-details__availability-legend-available-checkout">
								<span></span>
								Available for Check Out Only
							</div>
						</div>
						<p>If your dates are unavailable, try one of our other <a :href="props.roomInfo.parentLink" class="t-link">accommodation types</a></p>
					</div>
				</div>
			</div>
			<div v-if="data.selectedIndex == 1">
				<div class="c-room-details__availability__body c-room-details__availability--guest">
					<div class="c-room-details__availability__body-content">
						<p>Please select the number of guests</p>

						<div v-if="data.guest.adult > data.maxAdultOccupancy || data.guest.children > data.maxChildOccupancy">
							<div class="c-alert">
								<p class="c-alert__text error"><i class="iconf-warning"></i> Sorry, exceed guests capacity, please adjust your booking.</p>
							</div>
						</div>

						<form class="c-form">
							<div class="c-form__fields-set">
								<div class="c-form__field-group c-form__field-group-two-col">
									<div class="c-form__field">
										<label class="c-form__label" for="adult">Adult</label>
									</div>
									<div class="c-form__field c-form__number-wrapper">
										<a class="decrementer" @click.stop.prevent="CartHelper.decrementGuest(data, 'adult', getOffer)"><span class="iconf-minus"></span></a>
										<input v-model.number="data.guest.adult" @change="CartHelper.guestNumberTyped(data, 'adult', getOffer)" type="number" min="1" max="{{data.maxAdultOccupancy}}" disabled />
										<a class="incrementer" @click.stop.prevent="CartHelper.incrementGuest(data, 'adult', getOffer)"><span class="iconf-plus"></span></a>
									</div>
								</div>
								<div class="c-form__field-group c-form__field-group-two-col">
									<div class="c-form__field">
										<label class="c-form__label" for="children">Children <small>2 - 14 year old</small></label>
									</div>
									<div class="c-form__field c-form__number-wrapper">
										<a class="decrementer" @click.stop.prevent="CartHelper.decrementGuest(data, 'children', getOffer)"><span class="iconf-minus"></span></a>
										<input v-model.number="data.guest.children" @change="CartHelper.guestNumberTyped(data, 'children', getOffer)" type="number" min="0" max="{{data.maxChildOccupancy}}" disabled />
										<a class="incrementer" @click.stop.prevent="CartHelper.incrementGuest(data, 'children', getOffer)"><span class="iconf-plus"></span></a>
									</div>
								</div>
							</div>
							<div class="c-form__fields-set">
								<div class="c-form__field-group c-form__field-group-two-col">
									<div class="c-form__field">
										<label class="c-form__label" for="infant">Infant <small>0 - 2 year old</small></label>
									</div>
									<div class="c-form__field c-form__number-wrapper">
										<a class="decrementer" @click.stop.prevent="CartHelper.decrementGuest(data, 'infant', getOffer)"><span class="iconf-minus"></span></a>
										<input v-model.number="data.guest.infant" @change="CartHelper.guestNumberTyped(item, 'infant', getOffer)" type="number" min="0" max="{{data.maxChildOccupancy}}" disabled />
										<a class="incrementer" @click.stop.prevent="CartHelper.incrementGuest(data, 'infant', getOffer)"><span class="iconf-plus"></span></a>
									</div>
								</div>
							</div>
						</form>
						<p>If your dates are unavailable, try one of our other <a :href="props.roomInfo.parentLink" class="t-link">accommodation types</a></p>
					</div>
				</div>
			</div>
			<div v-if="data.selectedIndex == 2">

				<div class="c-room-details__availability__body c-room-details__availability--promo">
					<div class="c-room-details__availability__body-content">
						<p>If you have a promo code, please insert below</p>
						<form class="c-form c-form-horizontal c-form-shortform" @submit.prevent="getOffer">
							<div class="c-form__field">
								<label class="c-form__label" for="dob">Promo code</label>
								<input class="c-form__input" type="text" v-model="data.promoCode">
							</div>
							<input type="submit" class="c-form__submit-btn" value="Apply">
						</form>
						<p>If your dates are unavailable, try one of our other <a :href="props.roomInfo.parentLink" class="t-link">accommodation types</a></p>
					</div>
				</div>
			</div>
		</div>

		<offers-panel :booking-info="{
			image: props.roomInfo.image,
			hotelId: props.roomInfo.hotelId,
			roomId: props.roomInfo.roomId,
			parkName: props.roomInfo.parkName,
			isOffersLoaded: data.isOffersLoaded,
			date: {
				start: format_date(data.date.start,'YYYY-MM-DD'),
				end: format_date(data.date.end,'YYYY-MM-DD')
			},
			guest: {
				adult: data.guest.adult,
				children: data.guest.children,
				infant: data.guest.infant,
			},
			totalGuest: data.totalGuest,
			nights: data.nights,
			maxAdultOccupancy: data.maxAdultOccupancy,
			maxChildOccupancy: data.maxChildOccupancy,
			offers: data.offers,
			messages: data.messages
			}">
		</offers-panel>

	</div>

</template>

<script>
	//import { availabilityData } from "../ref/refData";
	import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
	import 'v-calendar/style.css';

	export default {
		name: 'availability-grid'
	};
</script>