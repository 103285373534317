
<script setup>
	import { ref, onMounted, onBeforeMount, onBeforeUpdate, onUpdated, defineProps, watch } from 'vue';
	import { editCart } from "../ref/refData";
	import { CartHelper } from 'Util/cartHelper';
	import { useScreens } from 'vue-screen-utils';
	const { mapCurrent } = useScreens({ xs: '0px', sm: '640px', md: '768px', lg: '1024px' });
	const columns = mapCurrent({ lg: 2 }, 1);
	const item = editCart;

	const yesterday = () => {
		let today = new Date();
		return today.setDate(today.getDate() - 1);
	}

	const attrs = ref([]);
	const newOfferData = ref({});
	const message = ref("");
	

	const disabledDates = ref([
		{
			start: null, end: new Date(yesterday())
		}
	])

	// get new availability based on date changes
	watch(() => item.value.date, (oldValue, newValue) => {
		const availabilityApiURL = `/api/hotels/${item.value.hotelId}/rooms/${item.value.roomTypeId}/availability/${format_date(new Date(), 'YYYY-MM')}/${format_date(item.value.months.next, 'YYYY-MM-DD')}`;

		checkAvailability(availabilityApiURL);

		getOffer();

		// update number of nights
		let dateStart = new Date(item.value.date.start);
		let dateEnd = new Date(item.value.date.end);
		let timeDiff = Math.abs(dateStart.getTime() - dateEnd.getTime());
		let numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

		item.value.nights = numberOfNights;
	});

	// init availability based on edit cart item
	watch(() => item.value.id, (oldValue, newValue) => {
		const availabilityApiURL = `/api/hotels/${item.value.hotelId}/rooms/${item.value.roomTypeId}/availability/${format_date(new Date(), 'YYYY-MM')}/${format_date(item.value.months.next, 'YYYY-MM-DD')}`;

		checkAvailability(availabilityApiURL);

	});

	// get total num of guest
	const getTotalGuest = (obj) => {
		return Object.values(obj).reduce((a, b) => a + b, 0);
	}

	// change date format
	const format_date = (value, formatType) => {
		return moment(value).format(formatType)
	}

	const clostModal = () => {
		message.value = '';
		// empty editcart ref data
		Object.keys(editCart).forEach(k => editCart[k] = editCart[k])
		editCart.value["isHidden"] = true;

		document.querySelector("body").classList.remove('c-modal__body-open--full');
	}

	// Update cart Handeler
	const updateCart = () => {

		var cartItems = JSON.parse(localStorage.getItem('cart'));

		// find old cart item from local storage
		let cartdata = cartItems.filter(function (cartitem) {
			return cartitem.id == item.value.id
		});
		// update values
		cartdata[0].conditions = newOfferData.value.conditions;

		if (newOfferData.value.dailyBreakdown) {
			cartdata[0].dailyBreakdown = newOfferData.value.dailyBreakdown;
		}
		
		cartdata[0].date.start = format_date(new Date(item.value.date.start), "YYYY-MM-DD");
		cartdata[0].date.end = format_date(new Date(item.value.date.end), "YYYY-MM-DD");
		cartdata[0].dimensionsRequired = newOfferData.value.dimensionsRequired;
		cartdata[0].infoPanel = newOfferData.value.infoPanel;
		cartdata[0].isAvailable = newOfferData.value.isAvailable;
		cartdata[0].guest = item.value.guest;
		cartdata[0].maxAdultOccupancy = item.value.maxAdultOccupancy;
		cartdata[0].maxChildOccupancy = item.value.maxChildOccupancy;
		cartdata[0].maxOccupancy = item.value.maxOccupancy;
		cartdata[0].totalAmount = newOfferData.value.totalAmount;
		cartdata[0].potentialDiscountAmount = newOfferData.value.potentialDiscountAmount;
		cartdata[0].yourDiscountAmount = newOfferData.value.yourDiscountAmount;
		cartdata[0].totalGuest = item.value.totalGuest;
		cartdata[0].urgencyText = newOfferData.value.urgencyText;
		cartdata[0].nights = item.value.nights;

		message.value = 'Item updated!';

		// save local storage cart items
		CartHelper.saveCart(cartItems);
		
		window.dispatchEvent(new CustomEvent('cart-updated', {
			detail: {
				storage: JSON.parse(localStorage.getItem("cart"))
			}
		}));
	}

	const changeMonth = (e) => {
		let currentMonth = format_date(item.value.months.current, "YYYY-MM");
		let prevMonth = format_date(item.value.months.prev, "YYYY-MM");
		let nextMonth;
		let getNextMonth;
		let startDate;

		if (!(prevMonth < e[0].id)) {
			return;
		}

		if (currentMonth == e[0].id) {
			let today = new Date();
			today.setDate(today.getDate());
			startDate = format_date(today, 'YYYY-MM-DD');
		} else {
			startDate = format_date(e[0].id, 'YYYY-MM');
		}

		if (typeof e[1] !== 'undefined') {
			getNextMonth = new Date(e[1].id);
		} else {
			getNextMonth = new Date(e[0].id);
		}

		nextMonth = new Date(getNextMonth.getFullYear(), getNextMonth.getMonth() + 1, 1);

		const availabilityApiURL = `/api/hotels/${item.value.hotelId}/rooms/${item.value.roomTypeId}/availability/${format_date(startDate, 'YYYY-MM')}/${format_date(nextMonth, 'YYYY-MM-DD')}`;

		checkAvailability(availabilityApiURL);
	}

	const checkAvailability = (url) => {
		fetch(url, {
			method: 'GET'
		})
			.then(res => res.json())
			.then((res) => {
				
				//console.log(res)

				if (res) {
					let dates = res.days;
					let availableDays = [];
					let unavailableForCheckIn = [];
					let availableForCheckOutOnly = [];

					item.value.maxAdultOccupancy = res.maxAdultOccupancy;
					item.value.maxChildOccupancy = res.maxChildOccupancy;
					item.value.maxOccupancy = res.maximumOccupancy;

					let unavailableDates = dates.filter(el => el.statusDescription === "Unavailable");

					unavailableDates.forEach((day) => {
						disabledDates.value.push({ start: day.date, end: day.date })
					})

					let availableDates = dates.filter(el => el.statusDescription === "Available");

					availableDates.forEach((day) => availableDays.push(new Date(day.date)));

					let unavailableForCheckInDates = dates.filter(el => el.statusDescription === "UnavailableForCheckIn");

					unavailableForCheckInDates.forEach((day) => unavailableForCheckIn.push(new Date(day.date)));

					let availableForCheckOutOnlyDates = dates.filter(el => el.statusDescription === "AvailableForCheckOutOnly");

					availableForCheckOutOnlyDates.forEach((day) => availableForCheckOutOnly.push(new Date(day.date)));

					let calendarAttributes = [
						{
							key: 'available',
							highlight: {
								color: 'available',
							},
							dates: availableDays
						},
						{
							key: 'unavailableForCheckIn',
							highlight: {
								color: 'unavailable-checkin',
							},
							dates: unavailableForCheckIn
						},
						{
							key: 'availableForCheckOutOnly',
							highlight: {
								color: 'available-checkout-only',
							},
							dates: availableForCheckOutOnly
						}
					];

					attrs.value = calendarAttributes;

				}

			})
			.catch(error => console.log(error.toString()))
	}

	const getOffer = () => {
		const pricingApiURL = `/api/hotels/${item.value.hotelId}/rooms/${item.value.roomTypeId}/pricing/${format_date(item.value.date.start, 'YYYY-MM-DD')}/${format_date(item.value.date.end, 'YYYY-MM-DD')}?adults=${item.value.guest.adult}&children=${item.value.guest.children}&infants=${item.value.guest.infant}&promoCode=${item.value.promoCode}&allotmentId=${item.value.allotmentId}`;
		
		fetch(pricingApiURL, {
			method: 'GET'
		})
			.then(res => res.json())
			.then((res) => {
				newOfferData.value = res.offers[0];
			})
			.catch(error => console.log(error.toString()))
	}
</script>

<template>
	<div class="c-modal c-modal__editcart" id="editCart" aria-modal="false" role="dialog" data-modal-body-open-class="c-modal__body-open--full" :aria-hidden="item.isHidden" @click.self="clostModal">
		<div class="c-modal__body">
			<button class="c-modal__close t-link" @click.stop="clostModal" aria-label="Close"></button>
			<div v-if="item.id">

				<DatePicker trim-weeks
							:first-day-of-week="2"
							v-model="item.date"
							id="AvailabilityCalender"
							:columns="columns"
							:attributes="attrs"
							:disabled-dates="disabledDates"
							v-model.range="range"
							@did-move="changeMonth" />

				<form class="c-form c-form__edit-cart">
					<div class="c-form__fields-set">
						<div class="c-form__field-group c-form__field-group-two-col">
							<div class="c-form__field">
								<label class="c-form__label" for="adult">Adult</label>
							</div>
							<div class="c-form__field c-form__number-wrapper">
								<a class="decrementer" @click.stop.prevent="CartHelper.decrementGuest(item, 'adult', getOffer)"><span class="iconf-minus"></span></a>
								<input v-model.number="item.guest.adult" @keyup="CartHelper.guestNumberTyped(item, 'adult', getOffer)" @change="CartHelper.guestNumberTyped(item, 'adult', getOffer)" type="number" min="0" max="{{item.maxAdultOccupancy}}" disabled />
								<a class="incrementer" @click.stop.prevent="CartHelper.incrementGuest(item, 'adult', getOffer)"><span class="iconf-plus"></span></a>
							</div>
						</div>
						<div class="c-form__field-group c-form__field-group-two-col">
							<div class="c-form__field">
								<label class="c-form__label" for="children">Children <small>2 - 14 year old</small></label>
							</div>
							<div class="c-form__field c-form__number-wrapper">
								<a class="decrementer" @click.stop.prevent="CartHelper.decrementGuest(item, 'children', getOffer)"><span class="iconf-minus"></span></a>
								<input v-model.number="item.guest.children" @keyup="CartHelper.guestNumberTyped(item, 'children', getOffer)" @change="CartHelper.guestNumberTyped(item, 'children', getOffer)" type="number" min="0" max="{{item.maxChildOccupancy}}" disabled />
								<a class="incrementer" @click.stop.prevent="CartHelper.incrementGuest(item, 'children', getOffer)"><span class="iconf-plus"></span></a>
							</div>
						</div>
					</div>
					<div class="c-form__fields-set">
						<div class="c-form__field-group c-form__field-group-two-col">
							<div class="c-form__field">
								<label class="c-form__label" for="infant">Infant <small>0 - 2 year old</small></label>
							</div>
							<div class="c-form__field c-form__number-wrapper">
								<a class="decrementer" @click.stop.prevent="CartHelper.decrementGuest(item, 'infant', getOffer)"><span class="iconf-minus"></span></a>
								<input v-model.number="item.guest.infant" @keyup="CartHelper.guestNumberTyped(item, 'infant', getOffer)" @change="CartHelper.guestNumberTyped(item, 'infant', getOffer)" type="number" min="0" max="{{item.maxChildOccupancy}}" disabled />
								<a class="incrementer" @click.stop.prevent="CartHelper.incrementGuest(item, 'infant', getOffer)"><span class="iconf-plus"></span></a>
							</div>
						</div>
					</div>
				</form>
			</div>

			
			<div class="c-button-wrap">
				<button @click.stop="clostModal" class="t-action c-button__close">
					Cancel
				</button>
				<button @click.stop="updateCart" class="t-action c-button__update-cart ">
					Update
				</button>
			</div>
			<div v-if="message">
				<div class="c-alert">
					<p class="c-alert__text success">{{message}}</p>
				</div>
			</div>
		</div>
		<span class="c-modal__dummy" tabindex="0"><!-- Dummy focusable element --></span>
	</div>
</template>

<script>
	import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
	import 'v-calendar/style.css';
	import moment from 'moment';

	export default {
		name: 'editcart',
		data() {
			return {
				text: null,
				expanded: false,
				button: false
			}
		},
		mounted() {
			//this.text = this.shareData.setText(text);
		},
		methods: {

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},

			clickHandler: function () {
				this.expanded = !this.expanded;
			},

			toggleHandler: function () {
				this.button = !this.button;
				//shareData.isOpen = !shareData.isOpen;
			}
		}
	};
</script>