/* app/ui/back-to-top/back-to-top */

import $ from 'jquery';
import {
	subscribe,
	// unsubscribe,
} from 'Util/pubsub';

var $window;
var $backButton;

var FadeOnTop = {
	init: function () {
		$window = $(window);
		$backButton = $('.js-fade-on-top');
		FadeOnTop._initEvent();
	},

	_initEvent: function () {
		subscribe('/window/scroll', FadeOnTop._buttonStatusCheck);
	},

	_buttonStatusCheck: function () {
		var newTopPos = $window.scrollTop();

		if (newTopPos > 300) {
			if (!$backButton.hasClass('fade-in')) {
				$backButton.addClass('fade-in');
			}
		}
		else {
			$backButton.removeClass('fade-in');
		}
	}
};

export { FadeOnTop };
