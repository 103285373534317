import jQuery from 'jquery';
import { activate } from 'Util/activate';
import * as keys from 'Util/keybinding';
import { publish, subscribe } from 'Util/pubsub';

/* Modal v2.0.0 */

var modal = (function ($, activate, keys, subscribe, publish) {
	var selectors = {
		modal: '.js-modal',
		body: '.js-modal-body, .js-modal__body',
		trigger: '.js-modal-trigger, .js-modal__trigger',
		close: '.js-modal-close, .js-modal__close'
	};

	var dataSelectors = {
		bodyOpenClass: 'modal-body-open-class'
	};

	var classes = {
		bodyOpen: 'c-modal__body-open'
	};

	var events = {
		// Subscriptions
		// Keep old events for backward compatibility
		open: '/modal/open /modal/show',
		close: '/modal/close /modal/hide',

		// Publications
		opened: '/modal/opened',
		closed: '/modal/closed'
	};

	var $focus = null; // The active modal window
	var $active = null; // The element that had focus before opening the modal window

	// Callback for passing into $().filter
	var focusable = function (i, el) {
		var $el = $(el);

		var focusIfNotDisabled = $el.is('input, select, textarea, button, object');
		var isNotDisabled = $el.is(':not(:disabled)');

		var focusThroughHref = $el.is('a, area') && $el.is('[href]');
		var focusThroughTabindex = $el.is('[tabindex]');

		var isFocusable;

		if (focusIfNotDisabled) {
			isFocusable = isNotDisabled;
		} else {
			isFocusable = focusThroughHref || focusThroughTabindex;
		}

		isFocusable = isFocusable && $el.is(':visible');

		return isFocusable;
	};

	var tabbable = function (i, el) {
		var $el = $(el);

		var isFocusable = focusable(i, el);
		var untabbableTabIndex = $el.is('[tabindex="-1"]');

		return isFocusable && !untabbableTabIndex;
	};

	var module = {
		init: function (options) {
			options = options || {};

			module._onOpen = options.onOpen || options.onShow || $.noop;

			module._initEvents();
			module._initSubscriptions();
		},

		_initEvents: function () {
			$(document)
				.on(activate.event, selectors.trigger, activate(module._processTriggerClick))
				.on(activate.event, selectors.close, activate(module._closeEvent));
		},

		_initSubscriptions: function () {
			subscribe(events.open, module.openById);
			subscribe(events.close, module.close);
		},

		_bindModalActiveEvents: function () {
			keys.bind('escape', module.close, true);
			$(document)
				.on('click', module._closeIfBackgroundClick)
				.on('focus', '*', module._wrapTab);
		},

		_unbindModalActiveEvents: function () {
			keys.unbind('escape', module.close);
			$(document)
				.off('click', module._closeIfBackgroundClick)
				.off('focus', '*', module._wrapTab);
		},

		// Event callbacks
		_processTriggerClick: function (e) {
			var $trigger = $(e.target).closest(selectors.trigger);
			var targetId = $trigger.attr('aria-controls');

			e.preventDefault();

			module.openById(targetId);
		},

		_wrapTab: function (e) {
			var $target = $(e.target);
			var $body = $active.find(selectors.body);
			var isInModal = !!$target.closest(selectors.body).length;
			var $tabbable;
			var afterModal = $body[0].compareDocumentPosition(e.target) === Node.DOCUMENT_POSITION_FOLLOWING;

			if (!isInModal) {
				e.preventDefault();

				$tabbable = module._getTabbable();

				if (afterModal) {
					// Wrap to start
					$tabbable[0].focus();
				} else {
					// Wrap to end
					$tabbable.last()[0].focus();
				}
			}
		},

		_closeIfBackgroundClick: function (e) {
			var $this = $(e.target);

			if ($this.closest(selectors.body).length) {
				// Click was within the modal popup, so ignore it
				return;
			} else {
				// Click was outside the modal popup, so close it
				module.close();
			}
		},

		// Open/Close functions
		openById: function (id) {
			var $modal = $('#' + id);

			module._open($modal);
		},

		_open: function ($modal) {
			var $firstFocusable;
			var bodyOpenClass;

			if ($modal.is(selectors.modal) === false) {
				// Don't try to open it if it's not a modal
				return;
			}

			bodyOpenClass = module._getBodyOpenClass($modal);

			if ($active) {
				// If there's already an active modal window,
				// keep remembering the same $focus element
				$active.attr('aria-hidden', true);
			} else {
				$focus = document.activeElement;
			}
			$active = $modal;

			$modal.attr('aria-hidden', false);
			$('body').addClass(bodyOpenClass);

			// Move focus within modal window
			$firstFocusable = module._getFocusable();
			if ($firstFocusable.length) {
				$firstFocusable[0].focus();
			}

			module._bindModalActiveEvents();

			module._onOpen($modal);
			publish(events.opened, $active);
		},

		_closeEvent: function (e) {
			e.preventDefault();
			module.close();
		},

		close: function () {
			var bodyOpenClass;

			if ($active) {
				bodyOpenClass = module._getBodyOpenClass($active);
				$active.attr('aria-hidden', true);
				$('body').removeClass(bodyOpenClass);

				module._unbindModalActiveEvents();

				// Return focus where it was
				if ($focus) {
					$focus.focus();
				}

				publish(events.closed, $active);

				$active = null;
				$focus = null;
			}
		},

		resizeBody: function () {
			console.error('modal.resize is no longer supported as of version 1.10');
		},

		_getBodyOpenClass: function ($modal) {
			var bodyOpenClass;

			bodyOpenClass = $modal.data(dataSelectors.bodyOpenClass) || classes.bodyOpen;

			return bodyOpenClass;
		},

		// Focus management
		_getFocusable: function ($modal) {
			var $body;
			var $descendents;
			var $focusable;

			$modal = $modal || $active;
			$body = $modal.find(selectors.body);

			$descendents = $body.find('*');
			$focusable = $descendents.filter(focusable);

			return $focusable;
		},

		_getTabbable: function ($modal) {
			var $body;
			var $descendents;
			var $tabbable;

			$modal = $modal || $active;
			$body = $modal.find(selectors.body);

			$descendents = $body.find('*');
			$tabbable = $descendents.filter(tabbable);

			return $tabbable;
		}
	};

	return {
		init: module.init,

		open: module.openById,
		close: module.close,
		resize: module.resizeBody
	};
})(jQuery, activate, keys, subscribe, publish);

export { modal };
