<script setup>
	import { defineProps } from 'vue';
	import { modalStatus } from "../ref/refData";
	// define props
	const props = defineProps({
		item: Object,
		index: Number
	})
</script>

<template>
	<button @click="add(props.item)" class="t-action btn__add-to-cart js-modal__trigger" :aria-controls="'addCart-' + props.index + props.item.allotmentId">Add To Cart</button>
	{{error}}
	<addedmodal :item="props.item" :index="props.index" :allotmentId="props.item.allotmentId"></addedmodal>
</template>

<script>
	import { CartHelper } from 'Util/cartHelper';

	export default {
		name: 'addtocart',
		props: ['item'],
		data() {
			return {
				cart: [],
				error: '',
				addedToCart: false
			}
		},
		mounted() {
			if (localStorage.getItem("cart")) {
				// assign cart local storage array object to data cart item
				this.cart = JSON.parse(localStorage.getItem("cart"))
			}

			window.addEventListener('cart-updated', (event) => {
				this.cart = event.detail.storage;
			});
		},
		methods: {
			refreshcart: function () {

				window.dispatchEvent(new CustomEvent('cart-updated', {
					detail: {
						storage: JSON.parse(localStorage.getItem("cart"))
					}
				}));

			},

			/**
			 * add to cart
			 * @param cartitem
			 */
			add: function (cartitem) {
				// define variable
				var cart = JSON.parse(localStorage.getItem("cart") || "[]");
				var newcartitem = cartitem;

				// reset modal status ref value
				modalStatus.value.message = '';
				let validation = this.validateBookingDate(newcartitem);

				// if pass the validation then save the item to cart localstorage
				if (validation) {
					newcartitem['id'] = crypto.randomUUID();
					cart.push(newcartitem);

					cart.sort((a, b) => {
						const dateA = a.date.start;
						const dateB = b.date.start;

						if (dateA < dateB) {
							return -1;
						}
						if (dateA > dateB) {
							return 1;
						}
						return 0;
					});

					CartHelper.saveCart(cart);
					this.addedToCart = true;
					this.refreshcart();
				}
			},

			/**
			 *  validate the booking dates
			 * @param item - cart item
			 * @returns {boolean}
			 */
			validateBookingDate: function (item) {

				var cartItems = JSON.parse(localStorage.getItem("cart") || "[]");
				let validation;

				// saving first booking item always returns true
				if (cartItems.length < 1) {

					validation = true;

				} else {

					const exactMatchMessage = "This booking is already in your cart";
					const overlapMessage = "You've tried to add a booking that has overlapping dates with another booking already in your cart. Our booking system will not allow you to proceed with two bookings in the one transaction if the stay dates overlap at any point. Please adjust your arrival or departure dates for one of these bookings to continue.";

					for (let i = 0, l = cartItems.length; i < l; i++) {
						let c = cartItems[i];

						// c.date is the date of the item in the cart
						// item.date is the date of the item attempting to be added

						let sameStartDate = (c.date.start == item.date.start && c.date.end == item.date.end) ? true : false;
						let sameEndDate = (c.date.start == item.date.start || c.date.end == item.date.end) ? true : false;
						let startDateOnRange = (item.date.start > c.date.start && item.date.start < c.date.end) ? true : false;
						let endDateOnRange = (item.date.end > c.date.start && item.date.end < c.date.end) ? true : false;
						let overlapRange = (item.date.start < c.date.start && item.date.end > c.date.end) ? true : false;

						if (sameStartDate || sameEndDate || startDateOnRange || endDateOnRange || overlapRange) {

							validation = false;

							// update error message
							if (sameStartDate && sameEndDate) {

								if (c.allotmentId == item.allotmentId && c.promoCode == item.promoCode) {

									modalStatus.value.message = exactMatchMessage;

								} else {

									modalStatus.value.message = overlapMessage;
								}

							} else {

								modalStatus.value.message = overlapMessage;
							}

							return validation;

						} else {

							validation = true;
						}
					}
				}

				return validation;
			},

		}
	};
</script>