<script setup>
	import { onMounted } from 'vue';
	import moment from 'moment';

	const props = defineProps({
		property: Object,
		roomInfo: Object,
		availabilityData: Object
	})

	let data = props.availabilityData;

	const format_date = (value, formatType) => {
		return moment(value).format(formatType)
	}

	const getOffers = async (pricingApiURL) => {
		try {
			const offersApi = await fetch(pricingApiURL);
			if (offersApi.ok) {
				const offersJson = await offersApi.json();
				return offersJson;
			} else {
				throw new Error(JSON.stringify({ code: offersApi.status, message: offersApi.statusText }))
			}
		} catch (error) {
			return [];
			//console.error(error);
		}
	}

	if (props.property) {
		let rooms = props.property;

		for (let i = 0; i < rooms.length; i++) {
			const room = rooms[i];
			const pricingApiURL = `/api/hotels/${props.roomInfo.hotelId}/rooms/${room.roomTypeId}/pricing/${format_date(data.date.start, 'YYYY-MM-DD')}/${format_date(data.date.end, 'YYYY-MM-DD')}?adults=${data.guest.adult}&children=${data.guest.children}&infants=${data.guest.infant}&promoCode=${data.promoCode}`;

			room.offers = await getOffers(pricingApiURL);
		}
		rooms.sort((a, b) => b.offers.offers.length - a.offers.offers.length);
	}

	onMounted(() => {

		let dateStart = data.date.start;
		let dateEnd = data.date.end;
		let timeDiff = Math.abs(dateStart.getTime() - dateEnd.getTime());
		let numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

		data.nights = numberOfNights;

	});

</script>

<template>
	<div v-for="room in property">
		<div class="c-park-finder-details__pod">
			<div class="c-park-finder-details__pod-details">
				<div class="c-park-finder-details__pod-image-wrapper">
					<div class="c-park-finder-details__pod-image" v-if="room.image">
						<picture v-if="room.image">
							<source :srcset="room.image.webpUrl" type="image/webp" />
							<img :src="room.image.url" />
						</picture>
					</div>
					<a :href="room.url" class="c-park-finder-details__pod-details-button mobile">View details</a>
				</div>
				<div class="c-park-finder-details__pod-copy">
					<div class="c-park-finder-details__pod-name">{{room.name}}</div>
					<div class="c-park-finder-details__attributes">
						<div class="c-park-finder-details__category">
							<span class="iconf-guest"></span>
							Sleeps {{room.sleeps}}
						</div>
						<div class="c-park-finder-details__category">
							<span class="iconf-Ensuite_shower"></span>
							<span v-for="(bath, index) in room.facilityCategories">
								<span v-if="index == 0">
									{{bath.name}}
								</span>
							</span>
						</div>
						<div class="c-park-finder-details__category">
							<span class="iconf-Kitchen"></span>
							<span v-for="(kitchen, index) in room.facilityCategories">
								<span v-if="index == 1">
									{{kitchen.name}}
								</span>
							</span>
						</div>
					</div>
					<a :href="room.url" class="c-park-finder-details__pod-details-button desktop">View details</a>
				</div>
			</div>
			<div class="c-park-finder-details__pod-offers">
				<offers-panel :booking-info="{
					image: room.image,
					hotelId: roomInfo.hotelId,
					roomId: room.roomTypeId,
					parkName: roomInfo.title,
					isOffersLoaded: data.isOffersLoaded,
					offersCollection: room.offers,
					date: {
						start: format_date(data.date.start,'YYYY-MM-DD'),
						end: format_date(data.date.end,'YYYY-MM-DD')
					},
					guest: {
						adult: data.guest.adult,
						children: data.guest.children,
						infant: data.guest.infant,
					},
					totalGuest: data.totalGuest,
					promoCode: data.promoCode,
					nights: data.nights,
					maxAdultOccupancy: data.maxAdultOccupancy,
					maxChildOccupancy: data.maxChildOccupancy
				}"></offers-panel>
			</div>
		</div>
	</div>
</template>

<script>
	import offersPanel from "../offersPanel/parkFinderOffersPanel";
	export default {
		name: 'rooms',
		components: {
			offersPanel
		}
	};
</script>