<script setup>
	import { shareData } from "../ref/refData";
	import { MapLoad } from 'App/map/map.load';
</script>

<template>
	<div class="c-parks-map js-parks-map" data-map-type="interactive"></div>
</template>

<script>
	//import { ref } from 'vue';
	export default {
		name: 'locations',
		props: ['MapData', 'ParkFinder'],
		data() {
			return {
				ParkFinder: this.ParkFinder,
				MapData: this.MapData,
			}
		},
		mounted: function () {
			this.getMapData();
		},
		watch: {
			MapData(newVal, oldVal) {
				this.getMapData();
			},
		},
		methods: {
			getMapData() {
				MapLoad.init(this.MapData, this.ParkFinder);
			}
		}
	};
</script>
